import React, { useEffect, useState } from "react";
import { Modal, Button, Row, Col, Container, Form } from "react-bootstrap";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { useAppSelector, useAppDispatch } from "../../../../store/hooks";
import {
	createLabourAsync,
	getAllLabourAsync,
} from "../../../../slices/settings/settingsSlice";
import { RootState } from "../../../../store/store";
import { toast } from "react-toastify";
interface CreateLabourValues {
	labour_type: string;
	labour_description: string;
	company_id: string;
}
interface CreateLabourProps {
	closeModal: () => void;
	show: boolean;
	onCreateSuccess: () => void;
}

const CreateLabour: React.FC<CreateLabourProps> = ({ closeModal, show, onCreateSuccess }) => {
	const dispatch = useAppDispatch();
	const [currentPage, setCurrentPage] = useState(1);

	const companyId = useAppSelector(
		(state: RootState) => state.auth.user?.company_id || "",
	);

	return (
		<Container fluid className="">
			<Modal
				centered
				size="lg"
				show={show}
				onHide={closeModal}
				style={{ maxHeight: "100vh", overflowY: "auto" }}
			>
				<div className="px-5">
					<Formik
						initialValues={{
							labour_type: "",
							labour_description: "",
							company_id: companyId || "",
						}}
						validationSchema={Yup.object().shape({
							labour_type: Yup.string().required("Required"),
							labour_description: Yup.string(),
							company_id: Yup.string().required("Required"),
						})}
						onSubmit={(values: CreateLabourValues, { setSubmitting }) => {
							console.log("Submitting values:", values);
							dispatch(
								createLabourAsync({
									...values,
									company_id: companyId || "",
								}),
							).unwrap()
								.then((response) => {
									toast.success("Labour created successfully");
									dispatch(getAllLabourAsync({ companyId, page: currentPage }));
									onCreateSuccess();
									closeModal();
								})
								.catch((error) => {
									const errorMessage = Object.values(error.message).flat().join(" ");
									toast.error(errorMessage);
								})
								.finally(() => {
									setSubmitting(false);
								});
						}}
					>
						{({
							errors,
							touched,
							handleSubmit,
							isSubmitting,
							values,
							handleChange,
						}) => (
							<Form onSubmit={handleSubmit}>
								<Modal.Header closeButton className="text-start mt-3">
									<h4 className="confirm-header">Add new labour type</h4>
								</Modal.Header>
								<Modal.Body>
									<Row>
										<Col className="mt-2">
											<label className="mt-2">
												<p>
													Labour type <span className="text-danger">*</span>
												</p>
											</label>
											<Field
												type="text"
												name="labour_type"
												placeholder="Enter labour type"
												className={`fluent-input  ${touched.labour_type && errors.labour_type
													? "is-invalid"
													: ""
													}`}
												required
											/>
										</Col>
									</Row>
									<Row>
										<Col className="mt-2">
											<label className="mt-2">
												<p>
													Description
												</p>
											</label>
											<Field
												type="text"
												name="labour_description"
												className={`fluent-input  ${touched.labour_description &&
													errors.labour_description
													? "is-invalid"
													: ""
													}`}

											/>
										</Col>
									</Row>
									<Modal.Footer className="mt-5">
										<Row>
											<Col md={6}>
												<div className=" mt-1">
													<Button onClick={closeModal} className="cancel-btn">
														<span className="text-center">Cancel </span>{" "}
													</Button>
												</div>
											</Col>
											<Col md={6}>
												<div className=" mt-1">
													<Button
														type="submit"
														className=" border-0 create-btn bg-color-1"
														disabled={isSubmitting}
													>
														<span className="text-center">Create labour</span>{" "}
													</Button>
												</div>
											</Col>
										</Row>
									</Modal.Footer>
								</Modal.Body>
							</Form>
						)}
					</Formik>
				</div>
			</Modal>
		</Container>
	);
};

export default CreateLabour;
