import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
    createMaterial, CreateMaterialResponse, CreateMaterialData,
    CreateLabourResponse, createLabour, CreateLabourData,
    createRole, CreateRoleData, CreateRoleResponse,
    UpdateMaterialResponse, UpdateLabourResponse, UpdateRoleResponse,
    updateMaterial, updateLabour, updateRole,
    UpdateMaterialData, UpdateLabourData, UpdateRoleData,
    createVendor, CreateVendorData, CreateVendorResponse,
    updateVendor, UpdateVendorData, UpdateVendorResponse,
    getAllMaterials, getAllLabour, getAllRoles, getAllVendors,
    getAllBanks, getAllPermissions,
    getAMaterial, getALabour, getARole, getAVendor,
    GetAMaterialResponse, GetALabourResponse, GetARoleResponse, GetAVendorResponse,
    createStage, CreateStageResponse, CreateStageData,
    updateStage, UpdateStageResponse, UpdateStageData,
    getAllStages, getAStage,
    GetAStageResponse,
    GetAllStagesResponse,
    GetAllVendorsResponse,
    GetAllMaterialsResponse,
    GetAllLabourResponse,
    GetAllRolesResponse,
} from "../../services/settings/settingsService";
import { getCompanyProfile, CompanyProfile, updateProfile, UpdateProfileData, UpdateProfileResponse } from "../../services/settings/settingsService";
import { updateCompanyProfile, UpdateCompanyProfileData, UpdateCompanyProfileResponse } from "../../services/settings/settingsService";

export interface SettingsState {
    banks: any[];
    permissions?: any[];
    companyProfile?: CompanyProfile | null;
    updateProfileResponse?: UpdateProfileResponse | null;
    updateCompanyProfileResponse?: UpdateCompanyProfileResponse | null;
    createMaterialResponse?: CreateMaterialResponse | null;
    getAMaterialResponse?: GetAMaterialResponse | null;
    updateMaterialResponse?: UpdateMaterialResponse | null;
    createLabourResponse?: CreateLabourResponse | null;
    getALabourResponse?: GetALabourResponse | null;
    updateLabourResponse?: UpdateLabourResponse | null;
    createRoleResponse?: CreateRoleResponse | null;
    getARoleResponse?: GetARoleResponse | null;
    updateRoleResponse?: UpdateRoleResponse | null;
    createVendorResponse?: CreateVendorResponse | null;
    getAVendorResponse?: GetAVendorResponse | null;
    updateVendorResponse?: UpdateVendorResponse | null;
    createStageResponse?: CreateStageResponse | null;
    getAStageResponse?: GetAStageResponse | null;
    updateStageResponse?: UpdateStageResponse | null;
    materialId?: string | null;
    labourId?: string;
    vendorId?: string;
    roleId?: string;
    stageId?: string;
    companyId?: string
    materials: GetAllMaterialsResponse | null;
    materialData?: any;
    labourTypes: GetAllLabourResponse | null;
    labourData?: any;
    roles: GetAllRolesResponse | null;
    roleData?: any;
    vendors: GetAllVendorsResponse | null;
    vendorData?: any;
    stages: GetAllStagesResponse | null;
    stageData?: any;
    meta?: any;
}

const initialState: SettingsState = {
    banks: [],
    permissions: [],
    companyProfile: null,
    updateProfileResponse: null,
    updateCompanyProfileResponse: null,
    createMaterialResponse: null,
    getAMaterialResponse: null,
    updateMaterialResponse: null,
    createLabourResponse: null,
    getALabourResponse: null,
    updateLabourResponse: null,
    createRoleResponse: null,
    getARoleResponse: null,
    updateRoleResponse: null,
    createVendorResponse: null,
    getAVendorResponse: null,
    updateVendorResponse: null,
    createStageResponse: null,
    getAStageResponse: null,
    updateStageResponse: null,
    materialId: null,
    labourId: "",
    roleId: "",
    vendorId: "",
    stageId: "",
    companyId: "",
    materials: null,
    materialData: null,
    labourTypes: null,
    labourData: null,
    roles: null,
    roleData: null,
    vendors: null,
    vendorData: null,
    stages: null,
    stageData: null,
    meta: null,
};


export const getAllBanksAsync = createAsyncThunk(
    'settings/getAllBanks',
    async () => {
        const response = await getAllBanks();
        return response.data;
    }
);

export const getAllPermissionsAsync = createAsyncThunk(
    'settings/getAllPermissions',
    async () => {
        const response = await getAllPermissions();
        console.log('Permissions fetched:', response.data);
        return response.data;
    }
);
export const getCompanyProfileAsync = createAsyncThunk(
    'settings/getCompanyProfile',

    async (companyId: string) => {
        const response = await getCompanyProfile(companyId);
        return response.data;
    }
);
export const updateProfileAsync = createAsyncThunk(
    'settings/updateProfile',
    async (updateProfileData: UpdateProfileData, { rejectWithValue }) => {
        try {
            const response = await updateProfile(updateProfileData);
            return response;
        } catch (error: any) {
            if (error.response && error.response.data) {
                return rejectWithValue(error.response.data);
            }
            return rejectWithValue({ error: true, message: "An unexpected error occurred" });
        }
    }
);
export const updateCompanyProfileAsync = createAsyncThunk(
    'settings/updateCompanyProfile',
    async (updateCompanyProfileData: UpdateCompanyProfileData) => {
        const response = await updateCompanyProfile(updateCompanyProfileData);
        return response;
    }
);

export const createMaterialAsync = createAsyncThunk(
    'settings/createMaterial',
    async (createMaterialData: CreateMaterialData, { dispatch, rejectWithValue }) => {
        try {
            const response = await createMaterial(createMaterialData);
            if (response.data && response.data.id) {
                const materialId = response.data.id;
                localStorage.setItem('materialId', materialId);
                dispatch(setMaterialId(materialId));
            }
            return response;
        } catch (error: any) {
            if (error.response && error.response.data) {
                return rejectWithValue(error.response.data);
            }
            return rejectWithValue({ error: true, message: "An unexpected error occurred" });
        }
    }
)
export const updateMaterialAsync = createAsyncThunk(
    'settings/updateMaterial',
    async ({ updateMaterialData, id, company_id }: { updateMaterialData: UpdateMaterialData, id: string, company_id: string }, { rejectWithValue }) => {
        try {
            const response = await updateMaterial(updateMaterialData, id);
            return response;
        } catch (error: any) {
            if (error.response && error.response.data) {
                return rejectWithValue(error.response.data);
            }
            return rejectWithValue({ error: true, message: "An unexpected error occurred" });
        }
    }
)
export const getAllMaterialsAsync = createAsyncThunk(
    'settings/getAllMaterials',
    async ({ companyId, page }: { companyId: string; page: number }) => {
        try {
            console.log('Company ID:', companyId);
            const materials = await getAllMaterials(companyId, page);
            return materials;
        } catch (error) {
            console.error('Error fetching materials:', error);
            throw error;
        }
    }
);
export const getAMaterialAsync = createAsyncThunk(
    'settings/getAMaterial',
    async ({ companyId, id }: { companyId: string; id: string }) => {
        try {
            console.log('Company ID:', companyId);
            const material = await getAMaterial(companyId, id);
            return material;
        } catch (error) {
            console.error('Error fetching material:', error);
            throw error;
        }
    }
);
export const createLabourAsync = createAsyncThunk(
    'settings/createLabour',
    async (createLabourData: CreateLabourData, { dispatch, rejectWithValue }) => {
        try {
            const response = await createLabour(createLabourData);
            if (response.data && response.data.id) {
                const labourId = response.data.id;
                localStorage.setItem('labourId', labourId);
                dispatch(setLabourId(labourId));
            }
            return response;
        } catch (error: any) {
            if (error.response && error.response.data) {
                return rejectWithValue(error.response.data);
            }
            return rejectWithValue({ error: true, message: "An unexpected error occurred" });
        }
    }
)
export const updateLabourAsync = createAsyncThunk(
    'settings/updateLabour',
    async ({ updateLabourData, id }: { updateLabourData: UpdateLabourData, id: string }, { rejectWithValue }) => {
        try {
            const response = await updateLabour(updateLabourData, id);
            return response;
        } catch (error: any) {
            if (error.response && error.response.data) {
                return rejectWithValue(error.response.data);
            }
            return rejectWithValue({ error: true, message: "An unexpected error occurred" });
        }
    }
)
export const getAllLabourAsync = createAsyncThunk(
    'settings/getAllLabour',
    async ({ companyId, page }: { companyId: string; page: number }) => {
        try {
            console.log('Company ID:', companyId);
            const labourTypes = await getAllLabour(companyId, page);
            return labourTypes;
        } catch (error) {
            console.error('Error fetching labour-types:', error);
            throw error;
        }
    }
);
export const getALabourAsync = createAsyncThunk(
    'settings/getALabour',
    async ({ companyId, id }: { companyId: string; id: string }) => {
        try {
            console.log('Company ID:', companyId);
            const labourType = await getALabour(companyId, id);
            return labourType;
        } catch (error) {
            console.error('Error fetching labour-type:', error);
            throw error;
        }
    }
);
export const createRoleAsync = createAsyncThunk(
    'settings/createRole',
    async (createRoleData: CreateRoleData, { dispatch, rejectWithValue }) => {
        try {
            const response = await createRole(createRoleData);
            if (response.data && response.data.id) {
                const roleId = response.data.id;
                localStorage.setItem('roleId', roleId);
                dispatch(setRoleId(roleId));
            }
            return response;
        } catch (error: any) {
            if (error.response && error.response.data) {
                return rejectWithValue(error.response.data);
            }
            return rejectWithValue({ error: true, message: "An unexpected error occurred" });
        }
    }
)
export const updateRoleAsync = createAsyncThunk(
    'settings/updateRole',
    async ({ updateRoleData, id }: { updateRoleData: UpdateRoleData, id: string }, { rejectWithValue }) => {
        try {
            const response = await updateRole(updateRoleData, id);
            return response;
        } catch (error: any) {
            if (error.response && error.response.data) {
                return rejectWithValue(error.response.data);
            }
            return rejectWithValue({ error: true, message: "An unexpected error occurred" });
        }
    }
)
export const getAllRolesAsync = createAsyncThunk(
    'material/getAllRoles',
    async ({ companyId, page }: { companyId: string; page: number }) => {
        try {
            console.log('Company ID:', companyId);
            const roles = await getAllRoles(companyId, page);
            return roles;
        } catch (error) {
            console.error('Error fetching roles:', error);
            throw error;
        }
    }
);
export const getARoleAsync = createAsyncThunk(
    'settings/getARole',
    async ({ companyId, id }: { companyId: string; id: string }) => {
        try {
            console.log('Company ID:', companyId);
            const role = await getARole(companyId, id);
            return role;
        } catch (error) {
            console.error('Error fetching material:', error);
            throw error;
        }
    }
);
export const createVendorAsync = createAsyncThunk(
    'settings/createVendor',
    async (createVendorData: CreateVendorData, { dispatch, rejectWithValue }) => {
        try {
            const response = await createVendor(createVendorData);
            if (response.data && response.data.id) {
                const VendorId = response.data.id;
                localStorage.setItem('vendorId', VendorId);
                dispatch(setVendorId(VendorId));
            }
            return response;
        } catch (error: any) {
            if (error.response && error.response.data) {
                return rejectWithValue(error.response.data);
            }
            return rejectWithValue({ error: true, message: "An unexpected error occurred" });
        }
    }
)
export const updateVendorAsync = createAsyncThunk(
    'settings/updateVendor',
    async ({ updateVendorData, id }: { updateVendorData: UpdateVendorData, id: string }, { rejectWithValue }) => {
        try {
            const response = await updateVendor(updateVendorData, id);
            return response;
        } catch (error: any) {
            if (error.response && error.response.data) {
                return rejectWithValue(error.response.data);
            }
            return rejectWithValue({ error: true, message: "An unexpected error occurred" });
        }
    }
)
export const getAllVendorsAsync = createAsyncThunk(
    'material/getAllVendors',
    async ({ companyId, page }: { companyId: string; page: number }) => {
        try {
            console.log('Company ID:', companyId);
            const vendors = await getAllVendors(companyId, page);
            return vendors;
        } catch (error) {
            console.error('Error fetching vendors:', error);
            throw error;
        }
    }
);
export const getAVendorAsync = createAsyncThunk(
    'settings/getAVendor',
    async ({ companyId, id }: { companyId: string; id: string }) => {
        try {
            console.log('Company ID:', companyId);
            const vendor = await getAVendor(companyId, id);
            return vendor;
        } catch (error) {
            console.error('Error fetching material:', error);
            throw error;
        }
    }
);
export const createStageAsync = createAsyncThunk(
    'settings/createStage ',
    async (createStageData: CreateStageData, { dispatch, rejectWithValue }) => {
        try {
            const response = await createStage(createStageData);
            if (response.data && response.data.id) {
                const StageId = response.data.id;
                localStorage.setItem('StageId', StageId);
                dispatch(setStageId(StageId));
            }
            return response;
        } catch (error: any) {
            if (error.response && error.response.data) {
                return rejectWithValue(error.response.data);
            }
            return rejectWithValue({ error: true, message: "An unexpected error occurred" });
        }
    }
)
export const updateStageAsync = createAsyncThunk(
    'settings/updateStage',
    async ({ updateStageData, id }: { updateStageData: UpdateStageData, id: string }, { rejectWithValue }) => {
        try {
            const response = await updateStage(updateStageData, id);
            return response;
        } catch (error: any) {
            if (error.response && error.response.data) {
                return rejectWithValue(error.response.data);
            }
            return rejectWithValue({ error: true, message: "An unexpected error occurred" });
        }
    }
)
export const getAllStagesAsync = createAsyncThunk(
    'material/getAllStages',
    async ({ companyId, page }: { companyId: string; page: number }) => {
        try {
            console.log('Company ID:', companyId);
            const stages = await getAllStages(companyId, page);
            return stages;
        } catch (error) {
            console.error('Error fetching stages:', error);
            throw error;
        }
    }
);
export const getAStageAsync = createAsyncThunk(
    'settings/getAStage',
    async ({ companyId, id }: { companyId: string; id: string }) => {
        try {
            console.log('Company ID:', companyId);
            const stage = await getAStage(companyId, id);
            return stage;
        } catch (error) {
            console.error('Error fetching stage:', error);
            throw error;
        }
    }
);

const settingsSlice = createSlice({
    name: "settings",
    initialState,
    reducers: {
        setcompanyId: (state, action) => {
            state.companyId = action.payload;
        },
        setMaterialId: (state, action) => {
            state.materialId = action.payload;
        },
        setLabourId: (state, action) => {
            state.labourId = action.payload;
        },
        setRoleId: (state, action) => {
            state.roleId = action.payload;
        },
        setVendorId: (state, action) => {
            state.vendorId = action.payload;
        },
        setStageId: (state, action) => {
            state.stageId = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getCompanyProfileAsync.fulfilled, (state, action) => {
            state.companyProfile = action.payload;
        });
        builder.addCase(getAllBanksAsync.fulfilled, (state, action) => {
            state.banks = action.payload;
        })
        builder.addCase(getAllPermissionsAsync.fulfilled, (state, action) => {
            state.permissions = action.payload;
        });
        builder.addCase(updateProfileAsync.fulfilled, (state, action) => {
            state.updateProfileResponse = action.payload;
        });
        builder.addCase(updateCompanyProfileAsync.fulfilled, (state, action) => {
            state.updateCompanyProfileResponse = action.payload;
        });
        builder.addCase(createMaterialAsync.fulfilled, (state, action) => {
            state.createMaterialResponse = action.payload;
        })
        builder.addCase(updateMaterialAsync.fulfilled, (state, action) => {
            state.updateMaterialResponse = action.payload;
        })
        builder.addCase(getAllMaterialsAsync.fulfilled, (state, action) => {
            state.materials = action.payload;
            state.meta = action.payload.meta;
        });
        builder.addCase(getAMaterialAsync.fulfilled, (state, action) => {
            state.materialData = action.payload;
        })
        builder.addCase(createLabourAsync.fulfilled, (state, action) => {
            state.createLabourResponse = action.payload;
        })
        builder.addCase(updateLabourAsync.fulfilled, (state, action) => {
            state.updateLabourResponse = action.payload;
        })
        builder.addCase(getAllLabourAsync.fulfilled, (state, action) => {
            state.labourTypes = action.payload;
            state.meta = action.payload.meta;
        });
        builder.addCase(getALabourAsync.fulfilled, (state, action) => {
            state.labourData = action.payload;
        });
        builder.addCase(createRoleAsync.fulfilled, (state, action) => {
            state.createRoleResponse = action.payload;
        })
        builder.addCase(updateRoleAsync.fulfilled, (state, action) => {
            state.updateRoleResponse = action.payload;
        })
        builder.addCase(getAllRolesAsync.fulfilled, (state, action) => {
            state.roles = action.payload;
            state.meta = action.payload.meta;
        });
        builder.addCase(getARoleAsync.fulfilled, (state, action) => {
            state.roleData = action.payload;
        });
        builder.addCase(createVendorAsync.fulfilled, (state, action) => {
            state.createVendorResponse = action.payload;
        })
        builder.addCase(updateVendorAsync.fulfilled, (state, action) => {
            state.updateVendorResponse = action.payload;
        })
        builder.addCase(getAllVendorsAsync.fulfilled, (state, action) => {
            state.vendors = action.payload;
            state.meta = action.payload.meta;
        });
        builder.addCase(getAVendorAsync.fulfilled, (state, action) => {
            state.vendorData = action.payload;
        });
        builder.addCase(createStageAsync.fulfilled, (state, action) => {
            state.createStageResponse = action.payload;
        })
        builder.addCase(updateStageAsync.fulfilled, (state, action) => {
            state.updateStageResponse = action.payload;
        })
        builder.addCase(getAllStagesAsync.fulfilled, (state, action) => {
            state.stages = action.payload;
            state.meta = action.payload.meta;
        });
        builder.addCase(getAStageAsync.fulfilled, (state, action) => {
            state.stageData = action.payload;
        });
    }
})

export const { setMaterialId, setLabourId, setRoleId, setVendorId, setcompanyId, setStageId } = settingsSlice.actions;
export default settingsSlice.reducer;
