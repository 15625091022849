import axios from 'axios';

axios.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

export interface Project {
    company_id: string | undefined;
    id: string;
    project_name: string;
    project_location: string;
    project_start_date: string;
    project_end_date: string;
    project_status_id: string;
    project_description: string;
}

export interface CreateProjectData {
    company_id: string | undefined;
    project_name: string;
    project_location: string;
    project_start_date: string;
    project_end_date: string;
    project_description: string;
}

export interface CreateProjectResponse {
    data: Project;
    message: string;
    error: boolean;
}

export interface UpdateProjectData {
    company_id: string;
    id: string;
    project_name: string;
    project_location: string;
    project_start_date: string;
    project_end_date: string;
}

export interface UpdateProjectResponse {
    data: Project;
    message: string;
    error: boolean;
}

export interface GetAllProjectsResponse {
    data: Project[];
    message: string;
}
export interface GetAProjectResponse {
    data: Project;
    message: string;
}

export const createProject = async (values: CreateProjectData): Promise<CreateProjectResponse> => {
    const response = await axios.post<CreateProjectResponse>('https://syteboxx-api.remsys.com.ng/api/v1/projects/create-project', values);
    return response.data;
}

export const updateProject = async (values: UpdateProjectData, id: string): Promise<UpdateProjectResponse> => {
    const response = await axios.put<UpdateProjectResponse>(`https://syteboxx-api.remsys.com.ng/api/v1/projects/update-project/${id}`, values);
    return response.data;
}

export const getAllProjects = async (companyId: string): Promise<Project[]> => {
    const response = await axios.get<GetAllProjectsResponse>(`https://syteboxx-api.remsys.com.ng/api/v1/projects/?company_id=${companyId}`);
    return response.data.data;
}

export const getAProject = async (companyId: string, id: string): Promise<Project> => {
    const response = await axios.get<CreateProjectResponse>(`https://syteboxx-api.remsys.com.ng/api/v1/projects/${id}/?company_id=${companyId}`);
    return response.data.data;
}
