import React, { useEffect, useState } from "react";
import { Modal, Row, Col, Container, Button, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import { useAppSelector, useAppDispatch } from "../../../../store/hooks";
import {
    getALabourRequestAsync, setLabourRequestId, reviewLabourRequestAsync, getAllLabourRequestsAsync
} from '../../../../slices/requests/requestsSlice';
import { RootState } from "../../../../store/store";
import { toast } from "react-toastify";

interface RejectLabourRequestValues {
    company_id: string;
    projectId: string;
    id: string;
    action: string;
    status: string;
}

interface RejectLabourRequestProps {
    closeModal: () => void;
    show: boolean;
    labourRequestId: string;
    projectId: string;
}

const RejectLabourRequest: React.FC<RejectLabourRequestProps> = ({ closeModal, show, labourRequestId, projectId }) => {
    const dispatch = useAppDispatch();
    const companyId = useAppSelector(
        (state: RootState) => state.auth.user?.company_id || "",
    );
    const labourRequestData = useAppSelector((state: any) => state.requests.labourRequestData);
    const [loading, setLoading] = useState(true);
    const [pageNumber, setPage] = useState(1);

    useEffect(() => {
        if (labourRequestId) {
            dispatch(setLabourRequestId(labourRequestId));
            console.log("Fetching labour request with ID:", labourRequestId);
            dispatch(getALabourRequestAsync({ companyId, id: labourRequestId, projectId })).then(() =>
                setLoading(false),
            );
        } else {
            console.log("labourRequestId is not set");
        }
    }, [dispatch, companyId, labourRequestId, projectId]);

    if (loading) {
        return null;
    }
    console.log("labourRequestData", labourRequestData);
    return (
        <Container>
            <Modal className="confirm-modal" size="lg" show={show} onHide={closeModal} style={{ maxHeight: "100vh", overflowY: "auto", maxWidth: "90vh" }}>
                <div className='px-4'>
                    <Formik
                        initialValues={{
                            company_id: companyId || "",
                            projectId: projectId || '',
                            id: labourRequestData?.Labour_requests?.id || '',
                            action: 'approve/reject',
                            status: 'rejected',
                        }}
                        onSubmit={(values: RejectLabourRequestValues, { setSubmitting }) => {
                            console.log(values);
                            const addSpacesToMessage = (message: any) => {
                                return message.replace(/([a-z])([A-Z])/g, '$1 $2');
                            };
                            dispatch(
                                reviewLabourRequestAsync({
                                    ...values,
                                }),
                            ).unwrap()
                                .then((response: any) => {
                                    toast.success("Labour request rejected successfully");
                                    dispatch(getAllLabourRequestsAsync({ companyId, projectId, page: pageNumber }));
                                    closeModal();
                                })
                                .catch((error: any) => {
                                    const errorMessage = error.message ? addSpacesToMessage(error.message) : "An unexpected error occurred";
                                    toast.error(errorMessage);
                                })
                                .finally(() => {
                                    setSubmitting(false);
                                });
                        }}
                    >
                        {({ handleSubmit }) => (
                            <Form onSubmit={handleSubmit}>
                                <Modal.Header closeButton className='text-start'>
                                    <h4 className="confirm-header">Reject labour request</h4>
                                </Modal.Header>
                                <Modal.Body>
                                    <p className="confirm-body">Are you sure want to reject this request?</p>
                                </Modal.Body>
                                <Modal.Footer className='mt-2'>
                                    <Row >
                                        <Col md={6}>
                                            <div className=' mt-1'>
                                                <Button onClick={closeModal} className='cancel-btn'>
                                                    <span className="text-center">No </span>                                        </Button>
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className=' mt-1'>
                                                <Button type='submit' className=' border-0 create-btn bg-color-1'>
                                                    <span className="text-center"> Yes</span>                                        </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Modal.Footer>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Modal>
        </Container>
    )
}

export default RejectLabourRequest;