import React, { useEffect, useState } from "react";
import { Image, Container, Navbar, Dropdown } from "react-bootstrap";
import Logo from "../../assets/images/logo.png";
import Moon from "../../assets/images/moon-vector.png";
import Bell from "../../assets/images/bell-vector.png";
import User from "../../assets/images/user-img.png";
import Arrow from "../../assets/images/dropdown-vector.png"
import { getAllProjectsAsync } from "slices/project/projectSlice";
import { setProjectId } from "slices/project/projectSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../store/store";

function NavBar() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const companyId = useAppSelector(
        (state: RootState) => state.auth.user?.company_id || "",
    );
    const [refreshData, setRefreshData] = useState(false);

    useEffect(() => {
        if (companyId) {
            dispatch(getAllProjectsAsync(companyId));
        }
    }, [companyId, dispatch]);

    useEffect(() => {
        if (refreshData) {
            dispatch(getAllProjectsAsync(companyId));
            setRefreshData(false);
        }
    }, [refreshData, companyId, dispatch]);

    const user = useAppSelector((state: RootState) => {
        const firstName = state.auth.user?.first_name || "";
        const lastName = state.auth.user?.last_name || "";
        return `${firstName} ${lastName}`.trim();
    });
    const projects = useAppSelector((state) => state.project.projects);

    const currentProjectId = useAppSelector(
        (state: RootState) => state.project.projectId
    );

    const handleProjectClick = (projectId: string) => {
        localStorage.removeItem("projectId");
        dispatch(setProjectId(projectId));
        localStorage.setItem("projectId", projectId);
        navigate(`/dashboard/${projectId}`);
    };

    console.log("Current Project ID:", currentProjectId);

    const handleViewAllProjects = () => {
        navigate("/projects");
    };

    return (
        <Navbar className="bg-color-9 fixed-top">
            <Container >
                <Navbar.Brand>
                    <Image src={Logo} width={90} height={30} />
                </Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse className="justify-content-end">
                    <Navbar.Text>
                        <div className="d-flex align-items-center">
                            <Dropdown>
                                <Dropdown.Toggle
                                    className="dropdown-toggle"
                                    variant="transparent"
                                    style={{
                                        background: "none",
                                        border: "none",
                                        boxShadow: "none",
                                    }}
                                >                                    <span>Projects <Image src={Arrow} /></span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {projects.length > 0 ? (
                                        projects.map((project: any) => (
                                            <Dropdown.Item
                                                key={project.id}
                                                onClick={() => handleProjectClick(project.id)}
                                            >                                                {project.project_name}
                                            </Dropdown.Item>
                                        ))
                                    ) : (
                                        <Dropdown.Item>No projects available</Dropdown.Item>
                                    )}
                                    <Dropdown.Divider />
                                    <Dropdown.Item
                                        onClick={handleViewAllProjects}
                                    >
                                        View All Projects
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <Image src={Moon} className="mx-3" />
                            <Image src={Bell} className="mx-3" />
                            <Image src={User} className="mx-3" />
                            <p className="mb-0 mx-1">{user}</p>
                        </div>
                    </Navbar.Text>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default NavBar