import React from 'react'
import SettingsLayout from '../settings-layout'

function Billings() {
    return (
        <div>
            <SettingsLayout>
                <div>Billings</div>
            </SettingsLayout>
        </div>
    )
}

export default Billings