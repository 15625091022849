import React, { useState, useEffect } from 'react';
import CreateMaterialBudget from './modals/create-material-budget';
import CreateLabourBudget from './modals/create-labour-budget';
import CreatePettyBudget from './modals/create-petty-budget';
import { Card, Image, Dropdown } from "react-bootstrap";
import Budget from "../../../assets/images/budget-icon.png";
import { getAllBudgetsAsync } from '../../../slices/budget/budgetSlice';
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { RootState } from "../../../store/store";
import { useParams } from "react-router-dom";
import { hasPermission } from "../../../util/permissionchecker";
import { extractPermissionNames } from '../../../util/transformPermissions';

function NoBudget() {
    const dispatch = useAppDispatch();
    const { projectId } = useParams<{ projectId: string }>();
    const userData = useAppSelector((state: any) => state.user?.userData?.user);
    const isAdmin = userData?.system_role === 'admin';
    const permissions = isAdmin ? null : extractPermissionNames(userData?.permissions?.permissions || []);

    console.log('User permissions:', permissions);
    const companyId = useAppSelector(
        (state: RootState) => state.auth.user?.company_id || "",
    );
    const [refreshData, setRefreshData] = useState(false);
    const [selectedBudgetType, setSelectedBudgetType] = useState<string | null>(null);
    const [createBudgetModalOpen, setCreateBudgetModalOpen] = useState<boolean>(false);

    const handleOpenCreateBudgetModal = (budgetType: string) => {
        setSelectedBudgetType(budgetType);
        setCreateBudgetModalOpen(true);
    };

    const handleCloseCreateBudgetModal = () => {
        setSelectedBudgetType(null);
        setCreateBudgetModalOpen(false);
    };
    useEffect(() => {
        if (companyId && projectId) {
            dispatch(getAllBudgetsAsync({ companyId, projectId }));
        }
    }, [companyId, dispatch, projectId]);

    useEffect(() => {
        if (refreshData && companyId && projectId) {
            dispatch(getAllBudgetsAsync({ companyId, projectId }));
            setRefreshData(false);
        }
    }, [refreshData, companyId, dispatch, projectId]);
    const handleCreateSuccess = () => {
        setRefreshData(true);
    };
    return (
        <div>
            <div className="pt-5 p-3">
                <Card className='auth-card-3 mt-5 pt-5'>
                    <div className='text-center'>
                        <Image src={Budget} width={178} height={125} className='my-4' />
                        <h3 className='text-color-2'>There is no budget created yet</h3>
                        <p className='text-color-2'>Click one of the options below to start:</p>
                        {hasPermission(permissions, "create-budget") && (

                            <Dropdown>
                                <Dropdown.Toggle variant="primary" id="dropdown-basic" className="my-3 py-3 px-5 border-0 bg-color-1 budget-btn">
                                    Create budget
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => handleOpenCreateBudgetModal('material')}>Material</Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleOpenCreateBudgetModal('labour')}>Labour</Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleOpenCreateBudgetModal('petty-cash')}>Petty Cash</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        )}
                    </div>
                </Card>
            </div>
            {selectedBudgetType === 'material' && (
                <CreateMaterialBudget show={createBudgetModalOpen} closeModal={handleCloseCreateBudgetModal} onCreateSuccess={handleCreateSuccess} projectId={projectId!} />
            )}
            {selectedBudgetType === 'labour' && (
                <CreateLabourBudget show={createBudgetModalOpen} closeModal={handleCloseCreateBudgetModal} onCreateSuccess={handleCreateSuccess} projectId={projectId!} />
            )}
            {selectedBudgetType === 'petty-cash' && (
                <CreatePettyBudget show={createBudgetModalOpen} closeModal={handleCloseCreateBudgetModal} onCreateSuccess={handleCreateSuccess} projectId={projectId!} />
            )}
        </div>
    )
}

export default NoBudget;
