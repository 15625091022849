import React, { useState, useEffect } from "react";
import SettingsLayout from "../settings-layout";
import { Nav, Table, Button, Row, Col, Dropdown } from "react-bootstrap";
import CreateMaterial from "./modals/create-material";
import EditMaterial from "./modals/edit-material";
import Action from "../../../assets/images/action-vector.png";
import CreateLabour from "./modals/create-labour";
import EditLabour from "./modals/edit-labour";
import {
	getAllMaterialsAsync,
	getAllLabourAsync,
} from "../../../slices/settings/settingsSlice";
import { getUserAsync } from "../../../slices/auth/authSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { RootState } from "../../../store/store";
import CloseButton from "../../../components/close-button/close-button";
import Pagination from '@mui/material/Pagination';

function Materials() {
	const dispatch = useAppDispatch();
	const materials = useAppSelector((state) => state.settings.materials?.data);
	const labourTypes = useAppSelector((state) => state.settings.labourTypes?.data);
	const materialMeta = useAppSelector(state => state.settings.materials?.meta);
	const labourMeta = useAppSelector(state => state.settings.labourTypes?.meta);
	const companyId = useAppSelector(
		(state: RootState) => state.auth.user?.company_id || "",
	);
	useEffect(() => {
		dispatch(getUserAsync());
	}, [dispatch]);

	const [activeTab, setActiveTab] = useState<string>("materials");
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
	const [selectedMaterialId, setSelectedMaterialId] = useState<string | null>(
		null,
	);
	const [selectedLabourId, setSelectedLabourId] = useState<string | null>(null);

	const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
	const handleOpenCreateModal = () => setCreateModalOpen(true);
	const handleCloseCreateModal = () => setCreateModalOpen(false);

	const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
	const handleOpenEditModal = (materialId: string) => {
		setSelectedMaterialId(materialId);
		setEditModalOpen(true);
	};
	const handleCloseEditModal = () => {
		setEditModalOpen(false);
		setSelectedMaterialId(null);
	};

	const [createLabourModalOpen, setCreateLabourModalOpen] =
		useState<boolean>(false);
	const handleOpenCreateLabourModal = () => setCreateLabourModalOpen(true);
	const handleCloseCreateLabourModal = () => setCreateLabourModalOpen(false);

	const [editLabourModalOpen, setEditLabourModalOpen] =
		useState<boolean>(false);
	const handleOpenEditLabourModal = (labourId: string) => {
		setSelectedLabourId(labourId);
		setEditLabourModalOpen(true);
	};
	const handleCloseEditLabourModal = () => {
		setEditLabourModalOpen(false);
		setSelectedLabourId(null);
	};

	const [dropdownOpen, setDropdownOpen] = useState<{ [key: number]: boolean }>(
		{},
	);
	const [labourDropdownOpen, setLabourDropdownOpen] = useState<{
		[key: number]: boolean;
	}>({});

	const toggleDropdown = (index: number) => {
		setDropdownOpen((prevState) => ({
			...prevState,
			[index]: !prevState[index],
		}));
	};

	const toggleLabourDropdown = (index: number) => {
		setLabourDropdownOpen((prevState) => ({
			...prevState,
			[index]: !prevState[index],
		}));
	};
	const [refreshData, setRefreshData] = useState(false);
	const [pageNumber, setPage] = useState(1);


	const handleChange = (event: React.ChangeEvent<unknown>, newPage: number) => {
		if (setPage) {
			setPage(newPage);
		}
	};

	useEffect(() => {
		if (companyId) {
			dispatch(getAllMaterialsAsync({ companyId, page: pageNumber }));
			dispatch(getAllLabourAsync({ companyId, page: pageNumber }));
		}
	}, [companyId, pageNumber, dispatch]);

	useEffect(() => {
		if (refreshData) {
			dispatch(getAllLabourAsync({ companyId, page: pageNumber }));
			setRefreshData(false);
		}
	}, [refreshData, companyId, pageNumber, dispatch]);
	useEffect(() => {
		if (refreshData) {
			dispatch(getAllMaterialsAsync({ companyId, page: pageNumber }));
			setRefreshData(false);
		}
	}, [refreshData, companyId, pageNumber, dispatch]);

	useEffect(() => {
		setActiveTab("materials");
	}, []);

	const tabOrder: string[] = ["materials", "labour-types"];

	const handleTabSelect = (key: string | null) => {
		if (key) {
			setActiveTabIndex(tabOrder.indexOf(key));
			setActiveTab(key);
		}
	};
	const handleCreateSuccess = () => {
		setRefreshData(true);
	};
	const handleEditSuccess = () => {
		setRefreshData(true);
	};

	console.log("Materials:", materials);
	console.log("LabourTypes:", labourTypes);

	return (
		<div className="bg-color-8">
			<SettingsLayout>
				<div className="pt-5 p-5">
					<div className="w-100 mb-5 d-inline-flex justify-content-between align-items-center">
						<h4 className="text-color-2">Materials & Labour types</h4>
						<CloseButton />
					</div>

					<Nav
						activeKey={activeTab}
						onSelect={handleTabSelect}
						className="nav px-2 py-2"
					>
						<Nav.Item>
							<Nav.Link
								className=" px-2 py-0 rounded fw-light mx-2"
								eventKey="materials"
								style={{
									textDecoration: "none",
									color: activeTab === "materials" ? " #FFFFFF " : " #747487",
									backgroundColor:
										activeTab === "materials" ? "#0F6CBD" : "transparent",
								}}
							>
								Materials
							</Nav.Link>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link
								className=" px-2 py-0 rounded fw-light mx-2"
								eventKey="labour-types"
								style={{
									color: activeTab === "labour-types" ? " #FFFFFF " : " #747487",
									textDecoration: "none",
									backgroundColor:
										activeTab === "labour-types" ? "#0F6CBD" : "transparent",
								}}
							>
								Labour types
							</Nav.Link>
						</Nav.Item>
					</Nav>

					<div>
						{activeTab === "materials" && (
							<div className="bg-color-9 px-5 pt-3 mt-4">
								<Row>
									<Col md={12}>
										<Button
											onClick={handleOpenCreateModal}
											className="py-3 px-5 border-0 bg-color-1 float-end"
										>
											<span className="text-center">Add material</span>
										</Button>
									</Col>
								</Row>
								<Table borderless responsive>
									<thead>
										<tr>
											<th className="text-color-1 bg-color-3">S/N</th>
											<th className="text-color-1 bg-color-3">Material name</th>
											<th className="text-color-1 bg-color-3">
												Unit of measurement
											</th>
											<th className="text-color-1 bg-color-3">
												Material threshold
											</th>
											<th className="text-color-1 bg-color-3">Action</th>
										</tr>
									</thead>
									<tbody>
										{Array.isArray(materials) && materials.length > 0 ? (
											materials.map((material, index) => (
												<tr key={material.id}>
													<td>{index + 1}</td>
													<td>{material.material_name}</td>
													<td>{material.material_unit}</td>
													<td>{material.material_threshold}</td>
													<td style={{ padding: "0", verticalAlign: "middle" }}>
														<Dropdown
															show={dropdownOpen[index]}
															onToggle={() => toggleDropdown(index)}
														>
															<Dropdown.Toggle
																className="dropdown-toggle"
																variant="transparent"
																style={{
																	background: "none",
																	border: "none",
																	boxShadow: "none",
																}}
															>
																<img
																	src={Action}
																	alt="Action"
																	className="action-icon mb-3"
																	style={{ margin: "0", padding: "0" }}
																/>
															</Dropdown.Toggle>
															<Dropdown.Menu>
																<Dropdown.Item
																	onClick={() =>
																		handleOpenEditModal(material.id)
																	}
																>
																	Edit
																</Dropdown.Item>
															</Dropdown.Menu>
														</Dropdown>
													</td>
												</tr>
											))
										) : (
											<tr>
												<td colSpan={6} className="text-center">
													No materials found.
												</td>
											</tr>
										)}
									</tbody>
								</Table>
								{materialMeta && materialMeta.total_pages > 1 && (
									<div className="d-flex justify-content-center mt-3 pb-5">
										<Pagination
											count={materialMeta && materialMeta?.total_pages}
											page={pageNumber}
											onChange={handleChange}
											shape="rounded"
											color="primary"
										/>
									</div>
								)}
							</div>
						)}
					</div>
					<div>
						{activeTab === "labour-types" && (
							<div className="bg-color-9 px-5 pt-3 mt-4">
								<Row>
									<Col md={12}>
										<Button
											onClick={handleOpenCreateLabourModal}
											className="py-3 px-5 border-0 bg-color-1 float-end"
										>
											<span className="text-center">Add labour</span>
										</Button>
									</Col>
								</Row>
								<Table borderless responsive>
									<thead>
										<tr>
											<th className="text-color-1 bg-color-3">S/N</th>
											<th className="text-color-1 bg-color-3">Labour type</th>
											<th className="text-color-1 bg-color-3">Description</th>
											<th className="text-color-1 bg-color-3">Action</th>
										</tr>
									</thead>
									<tbody>
										{Array.isArray(labourTypes) && labourTypes.length > 0 ? (
											labourTypes.map((labour, index) => (
												<tr key={labour.id}>
													<td>{index + 1}</td>
													<td>{labour.labour_type}</td>
													<td>{labour.labour_description}</td>
													<td style={{ padding: "0", verticalAlign: "middle" }}>
														<Dropdown
															show={labourDropdownOpen[index]}
															onToggle={() => toggleLabourDropdown(index)}
														>
															<Dropdown.Toggle
																className="dropdown-toggle"
																variant="transparent"
																style={{
																	background: "none",
																	border: "none",
																	boxShadow: "none",
																}}
															>
																<img
																	src={Action}
																	alt="Action"
																	className="action-icon mb-3"
																	style={{ margin: "0", padding: "0" }}
																/>
															</Dropdown.Toggle>
															<Dropdown.Menu>
																<Dropdown.Item
																	onClick={() =>
																		handleOpenEditLabourModal(labour.id)
																	}
																>
																	Edit
																</Dropdown.Item>
															</Dropdown.Menu>
														</Dropdown>
													</td>
												</tr>
											))
										) : (
											<tr>
												<td colSpan={4} className="text-center">
													No labour types found.
												</td>
											</tr>
										)}
									</tbody>
								</Table>
								{labourMeta && labourMeta.total_pages > 1 && (
									<div className="d-flex justify-content-center mt-3 pb-5">
										<Pagination
											count={labourMeta && labourMeta?.total_pages}
											page={pageNumber}
											onChange={handleChange}
											shape="rounded"
											color="primary"
										/>
									</div>
								)}
							</div>
						)}
					</div>
				</div>
				<CreateMaterial
					show={createModalOpen}
					closeModal={handleCloseCreateModal}
					onCreateSuccess={handleCreateSuccess}
				/>
				<EditMaterial
					show={editModalOpen}
					closeModal={handleCloseEditModal}
					materialId={selectedMaterialId!}
					onEditSuccess={handleEditSuccess}
				/>
				<CreateLabour
					show={createLabourModalOpen}
					closeModal={handleCloseCreateLabourModal}
					onCreateSuccess={handleCreateSuccess}
				/>
				<EditLabour
					show={editLabourModalOpen}
					closeModal={handleCloseEditLabourModal}
					labourId={selectedLabourId!}
					onEditSuccess={handleEditSuccess}
				/>
			</SettingsLayout>
		</div>
	);
}

export default Materials;
