import React, { useEffect, useState } from "react";
import { Modal, Button, Row, Col, Container, Form } from "react-bootstrap";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { useAppSelector, useAppDispatch } from "../../../../store/hooks";
import {
	createMaterialAsync,
	getAllMaterialsAsync,
} from "../../../../slices/settings/settingsSlice";
import { RootState } from "../../../../store/store";
import { toast } from "react-toastify";

interface CreateMaterialValues {
	material_name: string;
	material_unit: string;
	material_threshold: string;
	company_id: string;
}

interface CreateMaterialProps {
	closeModal: () => void;
	show: boolean;
	onCreateSuccess: () => void;
}

const CreateMaterial: React.FC<CreateMaterialProps> = ({
	closeModal,
	show,
	onCreateSuccess,
}) => {
	const dispatch = useAppDispatch();
	const [currentPage, setCurrentPage] = useState(1);

	const companyId = useAppSelector(
		(state: RootState) => state.auth.user?.company_id || "",
	);

	return (
		<Container fluid>
			<Modal
				centered
				size="lg"
				show={show}
				onHide={closeModal}
				style={{ maxHeight: "100vh", overflowY: "auto" }}
			>
				<div className="px-5">
					<Formik
						initialValues={{
							material_name: "",
							material_unit: "",
							material_threshold: "",
							company_id: companyId || "",
						}}
						validationSchema={Yup.object().shape({
							material_name: Yup.string().required("Required"),
							material_unit: Yup.string().required("Required"),
							material_threshold: Yup.string().required("Required"),
							company_id: Yup.string().required("Required"),
						})}
						onSubmit={(values: CreateMaterialValues, { setSubmitting }) => {
							console.log("Submitting values:", values);
							dispatch(
								createMaterialAsync({
									...values,
									company_id: companyId || "",
								}),
							).unwrap()
								.then((response) => {
									toast.success("Material created successfully");
									dispatch(getAllMaterialsAsync({ companyId, page: currentPage }));
									onCreateSuccess();
									closeModal();
								})
								.catch((error) => {
									const errorMessage = Object.values(error.message).flat().join(" ");
									toast.error(errorMessage);
								})
								.finally(() => {
									setSubmitting(false);
								});
						}}
					>
						{({
							errors,
							touched,
							handleSubmit,
							isSubmitting,
							values,
							handleChange,
						}) => (
							<Form onSubmit={handleSubmit}>
								<Modal.Header closeButton className="text-start mt-2">
									<h4 className="confirm-header">Add new material</h4>
								</Modal.Header>
								<Modal.Body>
									<Row>
										<Col className="mt-2">
											<label className="mt-2">
												<p>
													Material name <span className="text-danger">*</span>
												</p>
											</label>
											<Field
												type="text"
												name="material_name"
												placeholder="Enter material name"
												className={`fluent-input ${touched.material_name && errors.material_name
													? "is-invalid"
													: ""
													}`}
												required
											/>
											{touched.material_name && errors.material_name ? (
												<div className="invalid-feedback">
													{errors.material_name}
												</div>
											) : null}
										</Col>
									</Row>
									<Row>
										<Col className="mt-2">
											<label className="mt-2">
												<p>
													Unit of measurement{" "}
													<span className="text-danger">*</span>
												</p>
											</label>
											<Field
												as="select"
												name="material_unit"
												className={`fluent-input ${touched.material_unit && errors.material_unit
													? "is-invalid"
													: ""
													}`}
												required
											>
												<option value="">Select unit</option>
												<option value="Bag">Bag</option>
												<option value="Bucket">Bucket </option>
												<option value="Centimeter (cm) ">
													Centimeter (cm){" "}
												</option>
												<option value="Meter (m) ">Meter (m) </option>
												<option value="Kilogram (kg) ">Kilogram (kg) </option>
												<option value="Gram ">Gram </option>
												<option value="Square Meter (m2) ">
													Square Meter (m2){" "}
												</option>
												<option value="Tonne ">Tonne </option>
												<option value="Yard ">Yard </option>
												<option value="Foot ">Foot </option>
												<option value="Inch">Inch</option>
												<option value="Piece ">Piece </option>
												<option value="Liter ">Liter </option>
												<option value="Gallon ">Gallon </option>
												<option value="Packet ">Packet </option>
												<option value="Carton/Box ">Carton/Box </option>
												<option value="Truck ">Truck </option>
												<option value="Drum  ">Drum </option>
												<option value="Cubic Meter (m3) ">
													Cubic Meter (m3){" "}
												</option>
												<option value="Roll ">Roll </option>
											</Field>
											{touched.material_unit && errors.material_unit ? (
												<div className="invalid-feedback">
													{errors.material_unit}
												</div>
											) : null}
										</Col>
									</Row>
									<Row>
										<Col className="mt-2">
											<label className="mt-2">
												<p>
													Material threshold{" "}
													<span className="text-danger">*</span>
												</p>
											</label>
											<Field
												type="number"
												name="material_threshold"
												className={`fluent-input ${touched.material_threshold &&
													errors.material_threshold
													? "is-invalid"
													: ""
													}`}
												required
											/>
											{touched.material_threshold &&
												errors.material_threshold ? (
												<div className="invalid-feedback">
													{errors.material_threshold}
												</div>
											) : null}
										</Col>
									</Row>
									<Modal.Footer className="mt-5">
										<Row>
											<Col md={6}>
												<div className="mt-1">
													<Button onClick={closeModal} className="cancel-btn">
														<span className="text-center">Cancel</span>
													</Button>
												</div>
											</Col>
											<Col md={6}>
												<div className="mt-1">
													<Button
														type="submit"
														className="border-0 create-btn bg-color-1"
														disabled={isSubmitting}
													>
														<span className="text-center">Create material</span>
													</Button>
												</div>
											</Col>
										</Row>
									</Modal.Footer>
								</Modal.Body>
							</Form>
						)}
					</Formik>
				</div>
			</Modal>
		</Container>
	);
};

export default CreateMaterial;
