import React, { useEffect, useState } from "react";
import { Modal, Button, Row, Col, Container, Form } from "react-bootstrap";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { useAppSelector, useAppDispatch } from "../../../../store/hooks";
import {
	updateVendorAsync,
	getAllBanksAsync,
	getAllVendorsAsync,
	getAVendorAsync,
	setVendorId,
} from "../../../../slices/settings/settingsSlice";
import { RootState } from "../../../../store/store";
import { toast } from "react-toastify";
import {
	handleAccountNumberChange,
	handlePhoneNumberChange,
} from "util/inputUtils";

interface EditVendorValues {
	id: string;
	vendor_name: string;
	vendor_email: string;
	vendor_phone_number: string;
	vendor_bank_account: string;
	vendor_bank_id: string;
	company_id: string;
}
interface EditVendorProps {
	closeModal: () => void;
	show: boolean;
	vendorId: string;
	onEditSuccess: () => void;
}

const EditVendor: React.FC<EditVendorProps> = ({
	closeModal,
	show,
	vendorId,
	onEditSuccess,
}) => {
	const dispatch = useAppDispatch();
	const companyId = useAppSelector(
		(state: RootState) => state.auth.user?.company_id || "",
	);
	const vendorData = useAppSelector((state: any) => state.settings.vendorData);
	const banks = useAppSelector((state) => state.settings.banks);
	const [loading, setLoading] = useState(true);
	const [currentPage, setCurrentPage] = useState(1);

	useEffect(() => {
		dispatch(getAllBanksAsync());
	}, [dispatch]);

	useEffect(() => {
		if (vendorId) {
			dispatch(setVendorId(vendorId));
			console.log("Fetching vendor with ID:", vendorId);
			dispatch(getAVendorAsync({ companyId, id: vendorId })).then(() =>
				setLoading(false),
			);
		} else {
			console.log("vendorId is not set");
		}
	}, [dispatch, companyId, vendorId]);

	if (loading) {
		return null;
	}
	console.log("Banks", banks);

	return (
		<Container fluid className="">
			<Modal
				centered
				size="lg"
				show={show}
				onHide={closeModal}
				style={{ maxHeight: "100vh", overflowY: "auto" }}
			>
				<div className="px-5">
					<Formik
						enableReinitialize
						initialValues={{
							id: vendorData?.id || "",
							vendor_name: vendorData?.vendor_name || "",
							vendor_email: vendorData?.vendor_email || "",
							vendor_phone_number: vendorData?.vendor_phone_number || "",
							vendor_bank_account: vendorData?.vendor_bank_account || "",
							vendor_bank_id: vendorData?.vendor_bank_id || "",
							company_id: companyId || "",
						}}
						validationSchema={Yup.object().shape({
							vendor_name: Yup.string(),
							vendor_email: Yup.string(),
							vendor_phone_number: Yup.string()
								.matches(
									/^(070|080|081|090|091)[0-9]{8}$/,
									"Please enter a valid 11 digit phone number in Nigerian format e.g 08012345678",
								)
								.required("Please enter a valid 11 digit phone number"),
							vendor_bank_account: Yup.string(),
							vendor_bank_id: Yup.string(),
							company_id: Yup.string(),
						})}
						onSubmit={(values: EditVendorValues, { setSubmitting }) => {
							console.log("Submitting values:", values);
							dispatch(
								updateVendorAsync({
									updateVendorData: values,
									id: vendorId || "",
								}),
							)
								.unwrap()
								.then((response) => {
									toast.success("Vendor updated successfully");
									dispatch(getAllVendorsAsync({ companyId, page: currentPage }));
									onEditSuccess();
									closeModal();
								})
								.catch((error) => {
									const errorMessage = Object.values(error.message)
										.flat()
										.join(" ");
									toast.error(errorMessage);
								})
								.finally(() => {
									setSubmitting(false);
								});
						}}
					>
						{({
							errors,
							touched,
							handleSubmit,
							isSubmitting,
							values,
							handleChange,
						}) => (
							<Form onSubmit={handleSubmit}>
								<Modal.Header closeButton className="text-start mt-3">
									<h4 className="confirm-header">Edit vendor</h4>
								</Modal.Header>
								<Modal.Body>
									<Row>
										<Col className="mt-2">
											<label className="mt-2">
												<p>
													Vendor <span className="text-danger">*</span>
												</p>
											</label>
											<Field
												type="text"
												name="vendor_name"
												placeholder="Enter vendor name"
												className={`fluent-input  ${touched.vendor_name && errors.vendor_name
													? "is-invalid"
													: ""
													}`}
											/>
										</Col>
									</Row>
									<Row>
										<Col className="mt-2">
											<label className="mt-2">
												<p>
													Email <span className="text-danger">*</span>
												</p>
											</label>
											<Field
												type="text"
												name="vendor_email"
												placeholder="Enter vendor email"
												className={`fluent-input  ${touched.vendor_email && errors.vendor_email
													? "is-invalid"
													: ""
													}`}
											/>
										</Col>
									</Row>
									<Row>
										<Col className="mt-2">
											<label className="mt-2">
												<p>
													Phone number <span className="text-danger">*</span>
												</p>
											</label>
											<Field
												type="text"
												name="vendor_phone_number"
												placeholder="Enter vendor number"
												className={`fluent-input  ${touched.vendor_phone_number &&
													errors.vendor_phone_number
													? "is-invalid"
													: ""
													}`}
												onInput={handlePhoneNumberChange}
											/>
										</Col>
									</Row>
									<Row>
										<Col className="mt-2">
											<label className="mt-2">
												<p>
													Account number <span className="text-danger">*</span>
												</p>
											</label>
											<Field
												type="text"
												name="vendor_bank_account"
												placeholder="Enter account number"
												className={`fluent-input  ${touched.vendor_bank_account &&
													errors.vendor_bank_account
													? "is-invalid"
													: ""
													}`}
												onInput={handleAccountNumberChange}
											/>
										</Col>
									</Row>
									<Row>
										<Col className="mt-2">
											<label className="mt-2">
												<p>
													Bank <span className="text-danger">*</span>
												</p>
											</label>
											<Field
												as="select"
												name="vendor_bank_id"
												className={`fluent-input ${touched.vendor_bank_id && errors.vendor_bank_id
													? "is-invalid"
													: ""
													}`}
											>
												<option value="" label="Select bank" />
												{Array.isArray(banks) && banks.length > 0 ? (
													banks.map((bank: any) => (
														<option
															key={bank.id}
															value={bank.id}
															label={bank.bank_name}
														/>
													))
												) : (
													<p> no banks </p>
												)}
											</Field>
										</Col>
									</Row>
									<Modal.Footer className="mt-5">
										<Row>
											<Col md={6}>
												<div className=" mt-1">
													<Button onClick={closeModal} className="cancel-btn">
														<span className="text-center">Cancel </span>{" "}
													</Button>
												</div>
											</Col>
											<Col md={6}>
												<div className=" mt-1">
													<Button
														type="submit"
														className=" border-0 create-btn bg-color-1"
													>
														<span className="text-center">Edit vendor</span>{" "}
													</Button>
												</div>
											</Col>
										</Row>
									</Modal.Footer>
								</Modal.Body>
							</Form>
						)}
					</Formik>
				</div>
			</Modal>
		</Container>
	);
};

export default EditVendor;
