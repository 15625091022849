import React, { useState, useEffect } from "react";
import SettingsLayout from "../settings-layout";
// import SettingsLayout from "../settings-layout";
import Action from "../../../assets/images/action-vector.png";
import { Form, Button } from "react-bootstrap";
import SetupApprovalLevel from "./modals/setup-approval-level";
import CloseButton from "../../../components/close-button/close-button";
import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import { RootState } from "../../../store/store";
import { fetchCompanyApproversAsync, createApprovalLevelAsync, fetchCostomReviewersAsync, fetchProjectApproversAsync } from "../../../slices/approve/approveSlice"

import { toast } from "react-toastify";

import { Modal } from "react-bootstrap";

function ApprovalLevel() {
	const [setupApprovalLevelOpen, setSetupApprovalLevelOpen] =
		useState<boolean>(false);
	const [setupFreeApprovalOpen, setSetupFreeApprovalOpen] =
		useState<boolean>(false);

	const dispatch = useAppDispatch();
	const handleOpenSetupApprovalLevel = () => setSetupApprovalLevelOpen(true);
	const handleCloseSetupApprovalLevel = () => setSetupApprovalLevelOpen(false);
	const handleOpenSetupFreeApproval = () => setSetupFreeApprovalOpen(true);
	const handleCloseSetupFreeApproval = () => setSetupFreeApprovalOpen(false);

	const [singleApprovalUserId, setSingleApprovalUserId] = useState<
		string | null
	>(null);

	const projects = useAppSelector((state) => state.project.projects) || [];
	const approvers = useAppSelector((state: RootState) => state.approver.approvers);
	const customapprovers = useAppSelector((state: RootState) => state.approver.customapprovers);
	const projectapprover = useAppSelector(
		(state: RootState) => state.approver.projectapprovers);
	const companyId = useAppSelector((state: RootState) => state.auth.user?.company_id || 0);

	// const companyId = useAppSelector((state: RootState) => state.auth.user?.company_id || "",
	// );
	const projectId = projects.length > 0 ? projects[0].id : '';
	const handleApproverChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		setSingleApprovalUserId(e.target.value);
	};

	console.log("Reviwers", customapprovers);

	useEffect(() => {
		if (companyId) {
			dispatch(fetchCompanyApproversAsync(companyId));
			dispatch(
				fetchCostomReviewersAsync({ companyId: Number(companyId), projectId }),
			);
			dispatch(fetchProjectApproversAsync({ companyId: Number(companyId), projectId }));
		}
	}, [companyId, dispatch, projectId]);

	const createApproval = async () => {
		if (!singleApprovalUserId || !projectId || !companyId) {
			toast.error("Please select approver.");
			return;
		}
		const data = {
			id: projectId,
			company_id: companyId,
			user_id: singleApprovalUserId,
		};
		try {
			const response = await dispatch(createApprovalLevelAsync(data)).unwrap();
			console.log("Response:", response);
			toast.success("Approval level created successfully");
		} catch (error: any) {
			console.error("Error creating approver:", error);
			const errorMessage = error.message || "Failed to create approver.";
			toast.error(errorMessage);
		}
	};

	return (
		<div className="bg-color-8">
			<SettingsLayout>
				<div className="pt-5 p-5">
					<div className="w-100 d-inline-flex justify-content-between align-items-center">
						<h4 className="mb-5">Approval level</h4>
						<CloseButton />
					</div>
					<div>
						<h6 className="mb-3 text-color-2">Choose your preferred option</h6>
						{["radio"].map((type: string) => (
							<div key={`inline-${type}`} className="mb-3 d-flex flex-column">
								<Form.Check
									inline
									label="Default"
									name="group1"
									type={"radio"}
								/>

								<div></div>
								<p style={{ fontSize: "0.65em" }}>
									This option allows you to select only one approver
								</p>
								<select className="fluent-input w-20 mb-4" onChange={handleApproverChange}>
									<option>Select approver</option>
									{approvers.map((approver) => (
										<option key={approver.id} value={approver.id}>
											{approver.first_name} {approver.last_name}
										</option>
									))}
								</select>
								<Button className="bg-color-1 text-light border-0 w-20 py-3 px-4 mb-3" onClick={createApproval}>Save</Button>
								<Form.Check inline label="Custom" name="group1" type={"radio"} />
								<p style={{ fontSize: "0.65em" }}>
									This option allows you to select multi-level approval level
								</p>
								<Button
									onClick={handleOpenSetupApprovalLevel}
									className="bg-color-1 text-light border-0 w-fc py-3 px-4 mb-3"
								>
									Setup approval
								</Button>
							</div>
						))}
						<table className="mt-3 w-fc">
							<thead className="bg-color-3 text-color-1">
								<tr>
									<th className="p-2">S/N</th>
									<th className="p-2">Project name</th>
									<th className="p-2">Number of approval level</th>
									<th className="p-2"> </th>
								</tr>
							</thead>
							<tbody>
								{projects.map((project, index) => (
									<tr key={project.id}>
										<td className="p-2 text-center">{index + 1}</td>
										<td className="p-2">{project.project_name}</td>
										<td className="p-2"></td>
										<td className="p-2">
											<img
												src={Action}
												alt="Action"
												className="action-icon mb-3"
												style={{ margin: "0", padding: "0" }}
											/>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
				<SetupApprovalLevel
					show={setupApprovalLevelOpen}
					closeModal={handleCloseSetupApprovalLevel}
					projects={projects}
					approver={approvers}
					customapprover={customapprovers}
					projectapprover={projectapprover}


				/>
				<Modal
					show={setupFreeApprovalOpen}
					closeModal={handleCloseSetupFreeApproval}
					onHide={handleCloseSetupFreeApproval}
					centered
				>
					<Modal.Header closeButton>Change approver</Modal.Header>
					<Modal.Body>
						<select
							className="fluent-input w-100"
							onChange={handleApproverChange}
						>
							<option>Select approver</option>custom approvers
							{approvers.map((approver) => (
								<option key={approver.id} value={approver.id}>
									{approver.first_name} {approver.last_name}
								</option>
							))}
						</select>
					</Modal.Body>
					<Modal.Footer className="d-inline-flex justify-content-end align-items-center">
						<Button
							className="bg-body text-dark"
							onClick={handleCloseSetupFreeApproval}
						>
							Cancel
						</Button>
						<Button
							className="bg-color-1 text-light border-0"
							onClick={createApproval}
						>
							Save Changes
						</Button>
					</Modal.Footer>
				</Modal>
			</SettingsLayout>
		</div>
	);
}

export default ApprovalLevel;
