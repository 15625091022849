import React from "react";
import {
	Container,
	Table,
	Modal,
	CloseButton,
	Row,
	Col,
} from "react-bootstrap";
import { getAStoreRequestAsync } from "slices/inventory/inventorySlice";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { RootState } from "store/store";
import { format, isValid } from "date-fns";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
interface ViewStoreRequestProps {
	closeModal: () => void;
	id: string | null;
	project_id: string | undefined;
	company_id: string | null;
	show: boolean;
}
const ViewStoreRequest: React.FC<ViewStoreRequestProps> = ({
	closeModal,
	company_id,
	id,
	project_id,
	show,
}) => {
	const dispatch = useAppDispatch();
	const storeRequest = useAppSelector(
		(state: RootState) => state.inventory.storeRequest,
	);

	React.useEffect(() => {
		if (project_id && company_id && id !== null) {
			dispatch(
				getAStoreRequestAsync({ id, company_id, projectId: project_id }),
			);
		}
	}, [company_id, dispatch, id, project_id]);

	const formatDateTime = (dateTime: string | Date): string => {
		const date = new Date(dateTime);
		return isValid(date) ? format(date, "dd/MM/yy h:mm a") : "Invalid Date";
	};

	return (
		<Container fluid>
			<Modal
				size="lg"
				show={show}
				onHide={closeModal}
				centered
				className="p-3 overflow-scroll"
			>
				<Modal.Header className="text-start">
					<h4 className="confirm-header">View store request</h4>
					<CloseButton onClick={closeModal} />
				</Modal.Header>
				<Modal.Body className="w-100 d-inline-flex align-items-center px-2">
					<Row className="mt-2 pb-2">
						<Col md={8} className="col-separator">
							<Table className="rectangle" borderless responsive>
								<thead>
									<tr>
										<td className="text-color-7">Material </td>
										<td className="text-color-7">Labour type </td>
										<td className="text-color-7">Stage </td>
										<td className="text-color-7">Collected by </td>
										<td className="text-color-7">Qty collected </td>
									</tr>
								</thead>
								<tbody>
									<tr key={storeRequest?.confirmed_inventory?.id}>
										<td className="text-color-7">
											{storeRequest?.confirmed_inventory.material_name}{" "}
										</td>
										<td className="text-color-7">
											{storeRequest?.labour_type}
										</td>
										<td className="text-color-7">{storeRequest?.stage}</td>
										<td className="text-color-7">
											{storeRequest?.collected_by}
										</td>
										<td className="text-color-7">
											{storeRequest?.quantity_collected}
										</td>
									</tr>
								</tbody>
							</Table>
						</Col>
						<Col md={4}>
							<Timeline
								sx={{
									[`& .${timelineItemClasses.root}:before`]: {
										flex: 0,
										padding: 0,
									},
								}}
							>
								<TimelineItem>
									<TimelineSeparator>
										<TimelineDot
											sx={{
												bgcolor: "#0f6cbd",
												width: 15,
												height: 15,
											}}
											className="mt-4"
										/>{" "}
									</TimelineSeparator>
									<TimelineContent>
										<div style={{ fontSize: "16px" }} className="mb-1">
											<Row>
												<small>
													Request initiated by {storeRequest?.created_by}
												</small>
											</Row>
											<Row>
												<span
													className="text-color-2"
													style={{ fontSize: "0.7rem" }}
												>
													<small>
														{storeRequest?.confirmed_inventory?.created_at
															? formatDateTime(
																	storeRequest?.confirmed_inventory?.created_at,
															  )
															: "Date not available"}
													</small>
												</span>
											</Row>
										</div>
									</TimelineContent>
								</TimelineItem>
							</Timeline>
						</Col>
					</Row>
				</Modal.Body>
			</Modal>
		</Container>
	);
};

export default ViewStoreRequest;
