import React from "react";
import { Modal, Button, Row, Col, Container } from "react-bootstrap"; // Assuming you're using Bootstrap for your modal
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import {
	createProjectAsync,
	getAllProjectsAsync,
} from "../../../slices/project/projectSlice";
import { RootState } from "../../../store/store";
import { toast } from "react-toastify";

interface CreateProjectValues {
	company_id: string;
	project_name: string;
	project_location: string;
	project_start_date: string;
	project_end_date: string;
	project_description: string;
}
interface CreateProjectProps {
	closeModal: () => void;
	show: boolean;
	onCreateSuccess: () => void;
}

const CreateProject: React.FC<CreateProjectProps> = ({ closeModal, show, onCreateSuccess }) => {
	const dispatch = useAppDispatch();
	const companyId = useAppSelector(
		(state: RootState) => state.auth.user?.company_id || "",
	);


	return (
		<Container fluid className="">
			<Modal
				centered
				size="lg"
				show={show}
				onHide={closeModal}
				style={{ maxHeight: "100vh", overflowY: "auto" }}
			>
				<div className="px-5">
					<Formik
						initialValues={{
							company_id: companyId || "",
							project_name: "",
							project_location: "",
							project_start_date: "",
							project_end_date: "",
							project_description: "",
						}}
						validationSchema={Yup.object().shape({
							company_id: Yup.string().required("Required"),
							project_name: Yup.string().required("Required"),
							project_location: Yup.string().required("Required"),
							project_start_date: Yup.string().required("Required"),
							project_end_date: Yup.string().required("Required"),
							project_description: Yup.string(),
						})}
						onSubmit={(values: CreateProjectValues, { setSubmitting }) => {
							dispatch(
								createProjectAsync({
									...values,
									company_id: companyId || "",
									project_description: values.project_description || "",
								}),
							).unwrap()
								.then((response) => {
									toast.success("Project created successfully");
									dispatch(getAllProjectsAsync(companyId));
									onCreateSuccess();
									closeModal();
								})
								.catch((error) => {
									const errorMessage = Object.values(error.message).flat().join(" ");
									toast.error(errorMessage);
								})
								.finally(() => {
									setSubmitting(false);
								});
						}}
					>
						{({
							errors,
							touched,
							handleSubmit,
							isSubmitting,
							values,
							handleChange,
						}) => (
							<Form onSubmit={handleSubmit}>
								<Modal.Header closeButton className="text-start mt-2">
									<h4 className="confirm-header">Create project</h4>
								</Modal.Header>
								<Modal.Body>
									<Row>
										<Col md={6} className="mt-2">
											<label className="mt-2">
												<p>
													Project name <span className="text-danger">*</span>
												</p>
											</label>
											<Field
												type="text"
												name="project_name"
												placeholder="Enter your project name"
												className={`fluent-input  ${touched.project_name && errors.project_name
													? "is-invalid"
													: ""
													}`}
												required
											/>
										</Col>
										<Col md={6} className="mt-2">
											<label className="mt-2">
												<p>
													Location <span className="text-danger">*</span>
												</p>
											</label>
											<Field
												type="text"
												name="project_location"
												placeholder="Enter your location"
												className={`fluent-input  ${touched.project_location && errors.project_location
													? "is-invalid"
													: ""
													}`}
												required
											/>
										</Col>
									</Row>
									<Row>
										<Col md={6} className="mt-2">
											<label className="mt-2">
												<p>
													Start date <span className="text-danger">*</span>
												</p>
											</label>
											<Field
												type="date"
												name="project_start_date"
												className={`fluent-input  ${touched.project_start_date &&
													errors.project_start_date
													? "is-invalid"
													: ""
													}`}
												required
											/>
										</Col>
										<Col md={6} className="mt-2">
											<label className="mt-2">
												<p>
													End date <span className="text-danger">*</span>
												</p>
											</label>
											<Field
												type="date"
												name="project_end_date"
												className={`fluent-input  ${touched.project_end_date && errors.project_end_date
													? "is-invalid"
													: ""
													}`}
												required
											/>
										</Col>
									</Row>
									<Row>
										<Col md={12} className="mt-2">
											<label className="mt-2">
												<p>
													Description
												</p>
											</label>
											<Field
												as="textarea"
												name="project_description"
												placeholder="Enter a company description"
												className={`form-control  ${touched.project_description &&
													errors.project_description
													? "is-invalid"
													: ""
													}`}
												rows={5}
												cols={40}
											/>
										</Col>
									</Row>

									<Modal.Footer className="mt-5">
										<Row>
											<Col md={6}>
												<div className=" mt-1">
													<Button onClick={closeModal} className="cancel-btn">
														<span className="text-center">Cancel </span>{" "}
													</Button>
												</div>
											</Col>
											<Col md={6}>
												<div className=" mt-1">
													<Button
														type="submit"
														className="border-0 create-btn bg-color-1"
														disabled={isSubmitting}
													>
														<span className="text-center">Create project</span>{" "}
													</Button>
												</div>
											</Col>
										</Row>
									</Modal.Footer>
								</Modal.Body>
							</Form>
						)}
					</Formik>
				</div>
			</Modal>
		</Container>
	);
};

export default CreateProject;
