import React, { useEffect, useState } from "react";
import { Modal, Button, Row, Col, Container, Form } from "react-bootstrap";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { useAppSelector, useAppDispatch } from "../../../../store/hooks";
import {
    updateMaterialAsync,
    getAMaterialAsync,
    setMaterialId,
    getAllMaterialsAsync,
} from "../../../../slices/settings/settingsSlice";
import { RootState } from "../../../../store/store";
import { toast } from "react-toastify";

interface EditMaterialValues {
    company_id: string;
    id: string;
    material_unit: string;
    material_name: string;
    material_threshold: string;
}

interface EditMaterialProps {
    closeModal: () => void;
    show: boolean;
    materialId: string;
    onEditSuccess: () => void;
}

const EditMaterial: React.FC<EditMaterialProps> = ({
    closeModal,
    show,
    materialId,
    onEditSuccess,
}) => {
    const dispatch = useAppDispatch();
    const [currentPage, setCurrentPage] = useState(1);

    const companyId = useAppSelector(
        (state: RootState) => state.auth.user?.company_id || "",
    );
    // const materialData = useAppSelector(
    //     (state: any) => state.settings.materialData,
    // );

    const [loading, setLoading] = useState(true);

    const [initialValues, setInitialValues] = useState<EditMaterialValues>({
        company_id: "",
        id: "",
        material_unit: "",
        material_name: "",
        material_threshold: "",
    });

    useEffect(() => {
        if (materialId) {
            dispatch(setMaterialId(materialId));
            console.log("Fetching material with ID:", materialId);
            dispatch(getAMaterialAsync({ companyId, id: materialId })).then(
                (response: any) => {
                    const fetchedMaterialData = response.payload;
                    setLoading(false);
                    setInitialValues({
                        company_id: companyId || "",
                        id: response.payload.id || "",
                        material_unit: fetchedMaterialData?.material_unit || "",
                        material_name: response.payload.material_name || "",
                        material_threshold: response.payload.material_threshold || "",
                    });
                },
            );
        } else {
            console.log("materialId is not set");
        }
    }, [dispatch, companyId, materialId]);

    if (loading) {
        return null;
    }
    console.log("Initial values for Formik:", initialValues);

    return (
        <Container fluid className="">
            <Modal
                centered
                size="lg"
                show={show}
                onHide={closeModal}
                style={{ maxHeight: "100vh", overflowY: "auto" }}
            >
                <div className="px-5">
                    <Formik
                        enableReinitialize
                        initialValues={initialValues}
                        validationSchema={Yup.object().shape({
                            company_id: Yup.string(),
                            id: Yup.string(),
                            material_name: Yup.string(),
                            material_unit: Yup.string(),
                            material_threshold: Yup.string(),
                        })}
                        onSubmit={(values: EditMaterialValues, { setSubmitting }) => {
                            console.log(values);
                            dispatch(
                                updateMaterialAsync({
                                    updateMaterialData: values,
                                    id: materialId || "",
                                    company_id: companyId || "",
                                }),
                            ).unwrap()
                                .then((response) => {
                                    toast.success("Material updated successfully");
                                    dispatch(getAllMaterialsAsync({ companyId, page: currentPage }));
                                    onEditSuccess();
                                    closeModal();
                                })
                                .catch((error) => {
                                    const errorMessage = Object.values(error.message).flat().join(" ");
                                    toast.error(errorMessage);
                                })
                                .finally(() => {
                                    setSubmitting(false);
                                });
                        }}
                    >
                        {({
                            errors,
                            touched,
                            handleSubmit,
                            isSubmitting,
                            values,
                            handleChange,
                        }) => (
                            <Form onSubmit={handleSubmit}>
                                <Modal.Header closeButton className="text-start mt-2">
                                    <h4 className="confirm-header">Edit material</h4>
                                </Modal.Header>
                                <Modal.Body>
                                    <Row>
                                        <Col className="mt-2">
                                            <label className="mt-2">
                                                <p>
                                                    Material name <span className="text-danger">*</span>
                                                </p>
                                            </label>
                                            <Field
                                                type="text"
                                                name="material_name"
                                                placeholder="Enter material name"
                                                className="fluent-input"

                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="mt-2">
                                            <label className="mt-2">
                                                <p>
                                                    Unit of measurement{" "}
                                                    <span className="text-danger">*</span>
                                                </p>
                                            </label>
                                            <Field
                                                as="select"
                                                name="material_unit"
                                                value={values.material_unit}
                                                onChange={handleChange}
                                                className={`fluent-input  ${touched.material_unit && errors.material_unit
                                                    ? "is-invalid"
                                                    : ""
                                                    }`}
                                            >
                                                <option value="">Select unit</option>
                                                <option value="Bag">Bag</option>
                                                <option value="Bucket">Bucket </option>
                                                <option value="Centimeter (cm) ">
                                                    Centimeter (cm){" "}
                                                </option>
                                                <option value="Meter (m) ">Meter (m) </option>
                                                <option value="Kilogram (kg) ">Kilogram (kg) </option>
                                                <option value="Gram ">Gram </option>
                                                <option value="Square Meter (m2) ">
                                                    Square Meter (m2){" "}
                                                </option>
                                                <option value="Tonne ">Tonne </option>
                                                <option value="Yard ">Yard </option>
                                                <option value="Foot ">Foot </option>
                                                <option value="Inch">Inch</option>
                                                <option value="Piece ">Piece </option>
                                                <option value="Liter ">Liter </option>
                                                <option value="Gallon ">Gallon </option>
                                                <option value="Packet ">Packet </option>
                                                <option value="Carton/Box ">Carton/Box </option>
                                                <option value="Truck ">Truck </option>
                                                <option value="Drum  ">Drum </option>
                                                <option value="Cubic Meter (m3) ">
                                                    Cubic Meter (m3){" "}
                                                </option>
                                                <option value="Roll ">Roll </option>
                                            </Field>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="mt-2">
                                            <label className="mt-2">
                                                <p>
                                                    Material threshold{" "}
                                                    <span className="text-danger">*</span>
                                                </p>
                                            </label>
                                            <Field
                                                type="number"
                                                name="material_threshold"
                                                className={`fluent-input  ${touched.material_threshold &&
                                                    errors.material_threshold
                                                    ? "is-invalid"
                                                    : ""
                                                    }`}
                                            />
                                        </Col>
                                    </Row>
                                    <Modal.Footer className="mt-5">
                                        <Row>
                                            <Col md={6}>
                                                <div className=" mt-1">
                                                    <Button onClick={closeModal} className="cancel-btn">
                                                        <span className="text-center">Cancel</span>
                                                    </Button>
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className=" mt-1">
                                                    <Button
                                                        type="submit"
                                                        className=" border-0 create-btn bg-color-1"
                                                    >
                                                        <span className="text-center">Edit material</span>
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Modal.Footer>
                                </Modal.Body>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Modal>
        </Container>
    );
};

export default EditMaterial;
