import React, { useEffect, useState } from "react";
import { Modal, Row, Col, Container, Table } from 'react-bootstrap';
import { useAppSelector, useAppDispatch } from "../../../../store/hooks";
import {
    getALabourRequestAsync,
    setLabourRequestId,
} from '../../../../slices/requests/requestsSlice';
import { RootState } from "../../../../store/store";
import { format, isValid } from 'date-fns';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
interface ViewLabourRequestProps {
    closeModal: () => void;
    show: boolean;
    labourRequestId: string;
    projectId: string;
}

const ViewLabourRequest: React.FC<ViewLabourRequestProps> = ({ closeModal, show, labourRequestId, projectId }) => {
    const formatCurrency = (amount: number): string => {
        return `₦${amount.toLocaleString('en-US')}`;
    };
    const formatDateTime = (dateTime: string | Date): string => {
        const date = new Date(dateTime);
        return isValid(date) ? format(date, "dd/MM/yy h:mm a") : "Invalid Date";
    };
    const dispatch = useAppDispatch();
    const companyId = useAppSelector(
        (state: RootState) => state.auth.user?.company_id || "",
    );
    const labourRequestData = useAppSelector((state: any) => state.requests.labourRequestData);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (labourRequestId) {
            dispatch(setLabourRequestId(labourRequestId));
            console.log("Fetching labour request with ID:", labourRequestId);
            dispatch(getALabourRequestAsync({ companyId, id: labourRequestId, projectId })).then(() =>
                setLoading(false),
            );
        } else {
            console.log("labourRequestId is not set");
        }
    }, [dispatch, companyId, labourRequestId, projectId]);

    if (loading) {
        return null;
    }

    const reviewLevel = labourRequestData.Labour_requests.review_level ?? 0;
    const reviews = labourRequestData.Labour_requests.reviews ?? [];
    const approval = labourRequestData.Labour_requests.approval ?? null;

    const dotStyles = (step: number) => {
        const { status } = labourRequestData.Labour_requests;
        if (status === 'approved') return '#0f6cbd';
        if (status === 'reviewed' && step <= 2) return '#0f6cbd';
        if (status === 'pending' && step === 1) return '#0f6cbd';
        return '#ccc';
    };

    console.log("labourRequestData", labourRequestData.Labour_requests);
    return (
        <Container>
            <Modal centered size="xl" show={show} onHide={closeModal} style={{ maxHeight: "100vh", overflowY: "auto" }}>
                <div className='px-3'>
                    <Modal.Header closeButton className='text-start'>
                        <h4 className="confirm-header">View labour request</h4>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className='mt-2 pb-2'>
                            <Col md={8} className="col-separator">
                                <Table className="rectangle" borderless responsive >
                                    <thead >
                                        <tr>
                                            <td className='text-color-7'>Head name </td>
                                            <td className='text-color-7'>Labour </td>
                                            <td className='text-color-7'>Stage </td>
                                            <td className='text-color-7'>Amount </td>
                                            <td className='text-color-7'>Bank </td>
                                            <td className='text-color-7'>Acct no </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr key={labourRequestData?.Labour_requests?.id}>
                                            <td className='text-color-7'>{labourRequestData?.Labour_requests?.head_name} </td>
                                            <td className='text-color-7'>{labourRequestData?.Labour_requests?.labour_type} </td>
                                            <td className='text-color-7'>{labourRequestData?.Labour_requests?.stage}</td>
                                            <td className='text-color-7'>{formatCurrency(parseFloat(labourRequestData?.Labour_requests?.amount))}</td>
                                            <td className='text-color-7'>{labourRequestData?.Labour_requests?.bank}</td>
                                            <td className='text-color-7'>{labourRequestData?.Labour_requests?.account_number}</td>
                                        </tr>

                                    </tbody>
                                </Table>
                            </Col>
                            <Col md={4}>
                                <Timeline
                                    sx={{
                                        [`& .${timelineItemClasses.root}:before`]: {
                                            flex: 0,
                                            padding: 0,
                                        },
                                    }}
                                >
                                    <TimelineItem>
                                        <TimelineSeparator>
                                            <TimelineDot sx={{ bgcolor: dotStyles(1) }} />
                                            <TimelineConnector />
                                        </TimelineSeparator>
                                        <TimelineContent>
                                            <Row>
                                                <small>Request initiated by {labourRequestData.Labour_requests?.created_by} </small>
                                            </Row>
                                            <Row>
                                                <small>{formatDateTime(labourRequestData.Labour_requests.created_on)}</small>
                                            </Row>
                                        </TimelineContent>
                                    </TimelineItem>
                                    {reviewLevel > 1 && (
                                        <TimelineItem>
                                            <TimelineSeparator>
                                                <TimelineDot sx={{ bgcolor: dotStyles(2) }} />
                                                <TimelineConnector />
                                            </TimelineSeparator>
                                            <TimelineContent>
                                                <Row>
                                                    <small>Request reviewed by {reviews.find((review: { review_level: number; }) => review.review_level === 1)?.reviewer || "pending"}</small>
                                                </Row>
                                                <Row>
                                                    <small>{formatDateTime(reviews.find((review: { review_level: number; }) => review.review_level === 1)?.reviewed_at) || ""}</small>
                                                </Row>
                                            </TimelineContent>
                                        </TimelineItem>
                                    )}
                                    <TimelineItem>
                                        <TimelineSeparator>
                                            <TimelineDot sx={{ bgcolor: dotStyles(3) }} />
                                        </TimelineSeparator>
                                        <TimelineContent>
                                            <Row>
                                                <small>Request approved by {approval?.approver || "pending"} </small>
                                            </Row>
                                            <Row>
                                                <small>{formatDateTime(approval?.reviewed_at) || ""}</small>
                                            </Row>
                                        </TimelineContent>
                                    </TimelineItem>

                                </Timeline>
                            </Col>
                        </Row>
                    </Modal.Body>
                </div>
            </Modal>
        </Container>
    )
}

export default ViewLabourRequest