import React from "react";
import { Container, Modal, CloseButton, Table, Row, Col } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { RootState } from "store/store";
import { format, isValid } from "date-fns";
import { getAStoreRequestAsync } from "slices/inventory/inventorySlice";
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
interface ViewUsageProps {
	closeModal: () => void;
	id: string | null;
	project_id: string | undefined;
	company_id: string | null;
	show: boolean;
}

const ViewUsage: React.FC<ViewUsageProps> = ({
	closeModal,
	company_id,
	id,
	project_id,
	show,
}) => {
	const dispatch = useAppDispatch();

	const usage = useAppSelector(
		(state: RootState) => state.inventory.storeRequest,
	);

	React.useEffect(() => {
		if (project_id && company_id && id !== null) {
			dispatch(
				getAStoreRequestAsync({ id, company_id, projectId: project_id }),
			);
		}
	}, [company_id, dispatch, id, project_id]);

	const formatDateTime = (dateTime: string | Date): string => {
		const date = new Date(dateTime);
		return isValid(date) ? format(date, "dd/MM/yy h:mm a") : "Invalid Date";
	};

	console.log("usage:\n", usage);

	return (
		<Container fluid>
			<Modal
				size="lg"
				show={show}
				onHide={closeModal}
				centered
				className="p-3 overflow-scroll usage-modal"
			>
				<Modal.Header className='text-start'>
					<h4 className="confirm-header">View usage</h4>
					<CloseButton onClick={closeModal} />
				</Modal.Header>
				<Modal.Body className="w-100 d-inline-flex justify-content-around align-items-center px-2">
					<Row className='mt-2 pb-2'>
						<Col md={9} className="col-separator">
							<Table className="rectangle" borderless responsive >
								<thead >
									<tr>
										<td className='text-color-7'>Material </td>
										<td className='text-color-7'>Labour type </td>
										<td className='text-color-7'>Stage </td>
										<td className='text-color-7'>Qty collected </td>
										<td className='text-color-7'>Qty returned </td>
									</tr>
								</thead>
								<tbody>
									<tr key={usage?.confirmed_inventory?.id}>
										<td className='text-color-7'>{usage?.confirmed_inventory.material_name} </td>
										<td className='text-color-7'>{usage?.labour_type}</td>
										<td className='text-color-7'>{usage?.stage}</td>
										<td className='text-color-7'>{usage?.quantity_collected}</td>
										<td className='text-color-7'>{usage?.quantity_returned}</td>
									</tr>
								</tbody>
							</Table>
						</Col>
						<Col md={3}>
							<Timeline
								sx={{
									[`& .${timelineItemClasses.root}:before`]: {
										flex: 0,
										padding: 0,
									},
								}}
							>
								<TimelineItem>
									<TimelineSeparator>
										<TimelineDot sx={{
											bgcolor: '#0f6cbd',
											width: 15,
											height: 15,
										}} className="mt-4" />									</TimelineSeparator>
									<TimelineContent>
										<div style={{ fontSize: "14px" }} className="mb-1">
											<Row>
												<small> Collected by {usage?.collected_by}</small>
											</Row>
											<Row>
												<span className="text-color-2" style={{ fontSize: "0.7rem" }}>
													<small>
														{usage?.confirmed_inventory?.created_at
															? formatDateTime(usage?.confirmed_inventory?.created_at)
															: "Date not available"}
													</small>
												</span>
											</Row>
										</div>
									</TimelineContent>
								</TimelineItem>

							</Timeline>

						</Col>
					</Row>
				</Modal.Body>
			</Modal>
		</Container>
	);
};

export default ViewUsage;
