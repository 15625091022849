import React, { useState, useEffect } from 'react';
import Layout from '../../../components/layouts/layout';
import { Nav, Table, Dropdown } from 'react-bootstrap';
import PayMaterialRequest from "./modals/pay-material-request";
import PayLabourRequest from './modals/pay-labour-request';
import PayUtilityRequest from './modals/pay-utility-request';
import ViewMaterialRequest from './modals/view-pay-material-request';
import ViewLabourRequest from './modals/view-pay-labour-request';
import ViewUtilityRequest from './modals/view-pay-utility-request';
import Action from "../../../assets/images/action-vector.png"
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { RootState } from "../../../store/store";
import { getAllApprovedLabourRequestsAsync, getAllApprovedMaterialRequestsAsync, getAllApprovedPettyRequestsAsync } from '../../../slices/accounts/accountsSlice';
import { format } from 'date-fns';
import { hasPermission } from "../../../util/permissionchecker";
import { extractPermissionNames } from '../../../util/transformPermissions';
import Pagination from '@mui/material/Pagination';

function Accounts() {
    const formatCurrency = (amount: number): string => {
        return `₦${amount.toLocaleString('en-US')}`;
    };
    const userData = useAppSelector((state: any) => state.user?.userData?.user);
    const isAdmin = userData?.system_role === 'admin';
    const permissions = isAdmin ? null : extractPermissionNames(userData?.permissions?.permissions || []);

    console.log('User permissions:', permissions); const dispatch = useAppDispatch();
    const { projectId } = useParams<{ projectId: string }>();
    const companyId = useAppSelector(
        (state: RootState) => state.auth.user?.company_id || "",
    );
    const approvedMaterialRequests = useAppSelector((state) => state.accounts.approvedMaterialRequests?.data ?? [])
    const approvedMaterialRequestsMeta = useAppSelector((state) => state.accounts.approvedMaterialRequests?.meta)
    const approvedLabourRequests = useAppSelector((state) => state.accounts.approvedLabourRequests?.data)
    const approvedLabourRequestsMeta = useAppSelector((state) => state.accounts.approvedLabourRequests?.meta)
    const approvedPettyRequests = useAppSelector((state) => state.accounts.approvedPettyRequests?.data)
    const approvedPettyRequestsMeta = useAppSelector((state) => state.accounts.approvedPettyRequests?.meta)
    const [refreshData, setRefreshData] = useState(false);


    const [activeTab, setActiveTab] = useState<string>('material-request');
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
    useEffect(() => {
        setActiveTab('material-request');
    }, []);

    const tabOrder: string[] = ['material-request', 'labour-request', 'utility-request'];

    const handleTabSelect = (key: string | null) => {
        if (key) {
            setActiveTabIndex(tabOrder.indexOf(key));
            setActiveTab(key);
        }
    };
    const [selectedMaterialRequestId, setSelectedMaterialRequestId] = useState<string | null>(null);
    const [selectedLabourRequestId, setSelectedLabourRequestId] = useState<string | null>(null);
    const [selectedPettyRequestId, setSelectedPettyRequestId] = useState<string | null>(null);

    const [payMaterialRequestModalOpen, setPayMaterialRequestModalOpen] = useState<boolean>(false);
    const handleOpenPayMaterialRequestModal = (materialRequestId: string) => {
        setSelectedMaterialRequestId(materialRequestId);
        setPayMaterialRequestModalOpen(true);
    };
    const handleClosePayMaterialRequestModal = () => setPayMaterialRequestModalOpen(false);

    const [viewMaterialRequestModalOpen, setViewMaterialRequestModalOpen] = useState<boolean>(false);
    const handleOpenViewMaterialRequestModal = (materialRequestId: string) => {
        setSelectedMaterialRequestId(materialRequestId);
        setViewMaterialRequestModalOpen(true);
    };
    const handleCloseViewMaterialRequestModal = () => setViewMaterialRequestModalOpen(false);
    const [materialRequestDropdownOpen, setMaterialRequestDropdownOpen] = useState<{
        [key: number]: boolean;
    }>({});
    const toggleMaterialRequestDropdown = (index: number) => {
        setMaterialRequestDropdownOpen((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };

    const [payLabourRequestModalOpen, setPayLabourRequestModalOpen] = useState<boolean>(false);
    const handleOpenPayLabourRequestModal = (labourRequestId: string) => {
        setSelectedLabourRequestId(labourRequestId);
        setPayLabourRequestModalOpen(true);
    };

    const handleClosePayLabourRequestModal = () => setPayLabourRequestModalOpen(false);
    const [viewLabourRequestModalOpen, setViewLabourRequestModalOpen] = useState<boolean>(false);
    const handleOpenViewLabourRequestModal = (labourRequestId: string) => {
        setSelectedLabourRequestId(labourRequestId);
        setViewLabourRequestModalOpen(true);
    };

    const handleCloseViewLabourRequestModal = () => setViewLabourRequestModalOpen(false);
    const [labourRequestDropdownOpen, setLabourRequestDropdownOpen] = useState<{
        [key: number]: boolean;
    }>({});

    const toggleLabourRequestDropdown = (index: number) => {
        setLabourRequestDropdownOpen((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };

    const [payUtilityRequestModalOpen, setPayUtilityRequestModalOpen] = useState<boolean>(false);
    const handleOpenPayUtilityRequestModal = (pettyRequestId: string) => {
        setSelectedPettyRequestId(pettyRequestId);
        setPayUtilityRequestModalOpen(true);
    };

    const handleClosePayUtilityRequestModal = () => setPayUtilityRequestModalOpen(false);
    const [viewUtilityRequestModalOpen, setViewUtilityRequestModalOpen] = useState<boolean>(false);
    const handleOpenViewUtilityRequestModal = (pettyRequestId: string) => {
        setSelectedPettyRequestId(pettyRequestId);
        setViewUtilityRequestModalOpen(true);
    };

    const handleCloseViewUtilityRequestModal = () => setViewUtilityRequestModalOpen(false);
    const [utilityRequestDropdownOpen, setUtilityRequestDropdownOpen] = useState<{
        [key: number]: boolean;
    }>({});

    const toggleUtilityRequestDropdown = (index: number) => {
        setUtilityRequestDropdownOpen((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };
    const [pageNumber, setPage] = useState(1);
    const handleChange = (event: React.ChangeEvent<unknown>, newPage: number) => {
        if (setPage) {
            setPage(newPage);
        }
    };
    useEffect(() => {
        if (companyId && projectId) {
            console.log("companyId:", companyId);
            dispatch(getAllApprovedMaterialRequestsAsync({ companyId, projectId, page: pageNumber }));
            dispatch(getAllApprovedLabourRequestsAsync({ companyId, projectId, page: pageNumber }));
            dispatch(getAllApprovedPettyRequestsAsync({ companyId, projectId, page: pageNumber }));
        }
    }, [companyId, dispatch, projectId, pageNumber]);

    useEffect(() => {
        if (refreshData && companyId && projectId) {
            dispatch(getAllApprovedMaterialRequestsAsync({ companyId, projectId, page: pageNumber }));
            dispatch(getAllApprovedLabourRequestsAsync({ companyId, projectId, page: pageNumber }));
            dispatch(getAllApprovedPettyRequestsAsync({ companyId, projectId, page: pageNumber }));
            setRefreshData(false);
        }
    }, [refreshData, companyId, dispatch, projectId, pageNumber]);
    if (Array.isArray(approvedMaterialRequests)) {
        approvedMaterialRequests.forEach(request => {
            console.log("approved requests", request.batch_materials);
        });
    } else {
        console.log("approvedMaterialRequests is not an array:", approvedMaterialRequests);
    }


    return (
        <div className="bg-color-8">
            <Layout>
                <div className="mt-5 p-5">
                    <h3>Accounts</h3>
                    <Nav
                        activeKey={activeTab}
                        onSelect={handleTabSelect}
                        className="nav px-2 py-2"

                    >
                        <Nav.Item >
                            <Nav.Link
                                className="py-0 px-2 rounded fw-light mx-2"
                                eventKey="material-request"
                                style={{
                                    color:
                                        activeTab === 'material-request'
                                            ? ' #FFFFFF '
                                            : ' #747487', textDecoration: 'none',
                                    backgroundColor:
                                        activeTab === 'material-request'
                                            ? "#0F6CBD " : "transparent",
                                }}>Material request</Nav.Link>
                        </Nav.Item>
                        <Nav.Item className=''>
                            <Nav.Link eventKey="labour-request"
                                className="py-0 px-2 rounded fw-light mx-2"

                                style={{
                                    color:
                                        activeTab === 'labour-request'
                                            ? ' #FFFFFF '
                                            : ' #747487', textDecoration: 'none',
                                    backgroundColor:
                                        activeTab === 'labour-request' ? "#0F6CBD " : "transparent",
                                }}>Labour request</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="utility-request"
                                className="py-0 px-2 rounded fw-light mx-2"

                                style={{
                                    color:
                                        activeTab === 'utility-request'
                                            ? ' #FFFFFF '
                                            : ' #747487', textDecoration: 'none',
                                    backgroundColor:
                                        activeTab === 'utility-request'
                                            ? "#0F6CBD " : "transparent",
                                }}>Petty cash request</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <div>
                        {activeTab === "material-request" && (
                            <div className="bg-color-9 table-container px-5 pt-3 mt-5">
                                <Table borderless responsive className='request-table'>
                                    <thead >
                                        <tr>
                                            <th className='text-color-1 bg-color-3'>S/N</th>
                                            <th className='text-color-1 bg-color-3'>Date </th>
                                            <th className='text-color-1 bg-color-3'>Material </th>
                                            <th className='text-color-1 bg-color-3'>Total qty </th>
                                            <th className='text-color-1 bg-color-3'>Total amount </th>
                                            <th className='text-color-1 bg-color-3'>Vendor name</th>
                                            <th className='text-color-1 bg-color-3'> Status</th>
                                            <th className='text-color-1 bg-color-3'>Action</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {approvedMaterialRequests?.length > 0 ? (
                                            approvedMaterialRequests?.map((materialRequest, index) => {
                                                const { payment_status } = materialRequest;
                                                const getStatusClass = (payment_status: string) => {
                                                    switch (payment_status) {
                                                        case 'unpaid':
                                                            return 'text-color-8'; // Color: #e08213
                                                        case 'paid':
                                                            return 'text-color-10'; // Color: #187E2280
                                                        default:
                                                            return '';
                                                    }
                                                };

                                                const capitalizeFirstLetter = (payment_status: string) => {
                                                    return payment_status.charAt(0).toUpperCase() + payment_status.slice(1).toLowerCase();
                                                };
                                                return (
                                                    <tr key={materialRequest.id}>
                                                        <td>{index + 1}</td>
                                                        <td>{format(new Date(materialRequest.created_on), "dd/MM/yy")}</td>
                                                        <td>{materialRequest.batch_materials}</td>
                                                        <td>{materialRequest.total_quantity}</td>
                                                        <td>{formatCurrency(parseFloat(materialRequest.amount))}</td>
                                                        <td>{materialRequest.vendor_name}</td>
                                                        <td className={`${getStatusClass(materialRequest.payment_status)}  `}>
                                                            {capitalizeFirstLetter(materialRequest.payment_status)}
                                                        </td>
                                                        <td style={{ padding: '0', verticalAlign: 'middle' }}>
                                                            <Dropdown show={materialRequestDropdownOpen[index]} onToggle={() => toggleMaterialRequestDropdown(index)}>
                                                                <Dropdown.Toggle className="dropdown-toggle" variant="transparent" style={{ background: 'none', border: 'none', boxShadow: 'none' }}>
                                                                    <img src={Action} alt="Action" className="action-icon mb-3" style={{ margin: '0', padding: '0' }} />
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    {payment_status === 'unpaid' && (
                                                                        <>
                                                                            <Dropdown.Item onClick={() => handleOpenViewMaterialRequestModal(materialRequest.id)}>View</Dropdown.Item>
                                                                            {hasPermission(permissions, "pay-account") && (
                                                                                <Dropdown.Item onClick={() => handleOpenPayMaterialRequestModal(materialRequest.id)}>Pay</Dropdown.Item>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                    {payment_status === 'paid' && (
                                                                        <>
                                                                            <Dropdown.Item onClick={() => handleOpenViewMaterialRequestModal(materialRequest.id)}>View</Dropdown.Item>

                                                                        </>
                                                                    )}
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        ) : (
                                            <tr>
                                                <td colSpan={8} className="text-center">No material requests found.</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                                {approvedMaterialRequestsMeta && approvedMaterialRequestsMeta.total_pages > 1 && (
                                    <div className="d-flex justify-content-center mt-3 pb-5">
                                        <Pagination
                                            count={approvedMaterialRequestsMeta && approvedMaterialRequestsMeta?.total_pages}
                                            page={pageNumber}
                                            onChange={handleChange}
                                            shape="rounded"
                                            color="primary"
                                        />
                                    </div>
                                )}
                            </div>
                        )}

                    </div>
                    <div>
                        {activeTab === "labour-request" && (
                            <div className="bg-color-9 table-container px-5 pt-3 mt-5">
                                <Table borderless responsive className='request-table'>
                                    <thead >
                                        <tr>
                                            <th className='text-color-1 bg-color-3'>S/N</th>
                                            <th className='text-color-1 bg-color-3'>Date </th>
                                            <th className='text-color-1 bg-color-3'>Labour type </th>
                                            <th className='text-color-1 bg-color-3'>Stage </th>
                                            <th className='text-color-1 bg-color-3'>Amount </th>
                                            <th className='text-color-1 bg-color-3'>Status</th>
                                            <th className='text-color-1 bg-color-3'>Action</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Array.isArray(approvedLabourRequests) && approvedLabourRequests.length > 0 ? (
                                            approvedLabourRequests.map((labourRequest, index) => {
                                                const { payment_status } = labourRequest;
                                                const getStatusClass = (payment_status: string) => {
                                                    switch (payment_status) {
                                                        case 'unpaid':
                                                            return 'text-color-8'; // Color: #e08213
                                                        case 'paid':
                                                            return 'text-color-10'; // Color: #187E2280
                                                        default:
                                                            return '';
                                                    }
                                                };

                                                const capitalizeFirstLetter = (payment_status: string) => {
                                                    return payment_status.charAt(0).toUpperCase() + payment_status.slice(1).toLowerCase();
                                                };
                                                return (
                                                    <tr key={labourRequest.id}>
                                                        <td>{index + 1}</td>
                                                        <td> {format(new Date(labourRequest.created_on), "dd/MM/yy")} </td>
                                                        <td>{labourRequest.labour_type} </td>
                                                        <td>{labourRequest.stage}</td>
                                                        <td>{formatCurrency(parseFloat(labourRequest.amount))}</td>
                                                        <td className={`${getStatusClass(labourRequest.payment_status)}  `}>
                                                            {capitalizeFirstLetter(labourRequest.payment_status)}
                                                        </td>
                                                        <td style={{ padding: '0', verticalAlign: 'middle' }}>
                                                            <Dropdown show={labourRequestDropdownOpen[index]} onToggle={() => toggleLabourRequestDropdown(index)}>
                                                                <Dropdown.Toggle className="dropdown-toggle" variant="transparent" style={{ background: 'none', border: 'none', boxShadow: 'none' }}>
                                                                    <img src={Action} alt="Action" className="action-icon mb-3" style={{ margin: '0', padding: '0' }} />
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    {payment_status === 'unpaid' && (
                                                                        <>
                                                                            <Dropdown.Item onClick={() => handleOpenViewLabourRequestModal(labourRequest.id)}>View</Dropdown.Item>
                                                                            {hasPermission(permissions, "pay-account") && (
                                                                                <Dropdown.Item onClick={() => handleOpenPayLabourRequestModal(labourRequest.id)}>Pay</Dropdown.Item>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                    {payment_status === 'paid' && (
                                                                        <>
                                                                            <Dropdown.Item onClick={() => handleOpenViewLabourRequestModal(labourRequest.id)}>View</Dropdown.Item>
                                                                        </>
                                                                    )}
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        ) : (
                                            <tr>
                                                <td colSpan={8} className="text-center">No labour requests found.</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                                {approvedLabourRequestsMeta && approvedLabourRequestsMeta.total_pages > 1 && (
                                    <div className="d-flex justify-content-center mt-3 pb-5">
                                        <Pagination
                                            count={approvedLabourRequestsMeta && approvedLabourRequestsMeta?.total_pages}
                                            page={pageNumber}
                                            onChange={handleChange}
                                            shape="rounded"
                                            color="primary"
                                        />
                                    </div>
                                )}
                            </div>
                        )}

                    </div>
                    <div>
                        {activeTab === "utility-request" && (
                            <div className="bg-color-9 table-container px-5 pt-3 mt-5">
                                <Table borderless responsive className='request-table'>
                                    <thead >
                                        <tr>
                                            <th className='text-color-1 bg-color-3'>S/N</th>
                                            <th className='text-color-1 bg-color-3'>Date </th>
                                            <th className='text-color-1 bg-color-3'> Title </th>
                                            <th className='text-color-1 bg-color-3'>Receiver name </th>
                                            <th className='text-color-1 bg-color-3'>Amount </th>
                                            <th className='text-color-1 bg-color-3'> Status</th>
                                            <th className='text-color-1 bg-color-3'>Action</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Array.isArray(approvedPettyRequests) && approvedPettyRequests.length > 0 ? (
                                            approvedPettyRequests.map((pettyRequest, index) => {
                                                const { payment_status } = pettyRequest;
                                                const getStatusClass = (payment_status: string) => {
                                                    switch (payment_status) {
                                                        case 'unpaid':
                                                            return 'text-color-8'; // Color: #e08213
                                                        case 'paid':
                                                            return 'text-color-10'; // Color: #187E2280
                                                        default:
                                                            return '';
                                                    }
                                                };

                                                const capitalizeFirstLetter = (payment_status: string) => {
                                                    return payment_status.charAt(0).toUpperCase() + payment_status.slice(1).toLowerCase();
                                                };
                                                return (
                                                    <tr key={pettyRequest.id}>
                                                        <td>{index + 1}</td>
                                                        <td> {format(new Date(pettyRequest.created_on), "dd/MM/yy")} </td>
                                                        <td>{pettyRequest.title}</td>
                                                        <td>{pettyRequest.receiver_name}</td>
                                                        <td>{formatCurrency(parseFloat(pettyRequest.amount))}</td>
                                                        <td className={`${getStatusClass(pettyRequest.payment_status)}  `}>
                                                            {capitalizeFirstLetter(pettyRequest.payment_status)}
                                                        </td>
                                                        <td style={{ padding: '0', verticalAlign: 'middle' }}>
                                                            <Dropdown show={utilityRequestDropdownOpen[index]} onToggle={() => toggleUtilityRequestDropdown(index)}>
                                                                <Dropdown.Toggle className="dropdown-toggle" variant="transparent" style={{ background: 'none', border: 'none', boxShadow: 'none' }}>
                                                                    <img src={Action} alt="Action" className="action-icon mb-3" style={{ margin: '0', padding: '0' }} />
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    {payment_status === 'unpaid' && (
                                                                        <>
                                                                            <Dropdown.Item onClick={() => handleOpenViewUtilityRequestModal(pettyRequest.id)}>View</Dropdown.Item>
                                                                            {hasPermission(permissions, "pay-account") && (
                                                                                <Dropdown.Item onClick={() => handleOpenPayUtilityRequestModal(pettyRequest.id)}>Pay</Dropdown.Item>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                    {payment_status === 'paid' && (
                                                                        <>
                                                                            <Dropdown.Item onClick={() => handleOpenViewUtilityRequestModal(pettyRequest.id)}>View</Dropdown.Item>
                                                                        </>
                                                                    )}
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        ) : (
                                            <tr>
                                                <td colSpan={8} className="text-center">No petty cash requests found.</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                                {approvedPettyRequestsMeta && approvedPettyRequestsMeta.total_pages > 1 && (
                                    <div className="d-flex justify-content-center mt-3 pb-5">
                                        <Pagination
                                            count={approvedPettyRequestsMeta && approvedPettyRequestsMeta?.total_pages}
                                            page={pageNumber}
                                            onChange={handleChange}
                                            shape="rounded"
                                            color="primary"
                                        />
                                    </div>
                                )}
                            </div>
                        )}

                    </div>
                </div>
                <PayMaterialRequest show={payMaterialRequestModalOpen} closeModal={handleClosePayMaterialRequestModal} projectId={projectId!} materialRequestId={selectedMaterialRequestId!} />
                <PayLabourRequest show={payLabourRequestModalOpen} closeModal={handleClosePayLabourRequestModal} projectId={projectId!} labourRequestId={selectedLabourRequestId!} />
                <PayUtilityRequest show={payUtilityRequestModalOpen} closeModal={handleClosePayUtilityRequestModal} projectId={projectId!} pettyRequestId={selectedPettyRequestId!} />
                <ViewMaterialRequest show={viewMaterialRequestModalOpen} closeModal={handleCloseViewMaterialRequestModal} projectId={projectId!} materialRequestId={selectedMaterialRequestId!} />
                <ViewLabourRequest show={viewLabourRequestModalOpen} closeModal={handleCloseViewLabourRequestModal} projectId={projectId!} labourRequestId={selectedLabourRequestId!} />
                <ViewUtilityRequest show={viewUtilityRequestModalOpen} closeModal={handleCloseViewUtilityRequestModal} projectId={projectId!} pettyRequestId={selectedPettyRequestId!} />

            </Layout>
        </div>
    )
}

export default Accounts